import { createContext, useContext, useEffect, useState } from "react";
import { getAuth2, signIn, signOut } from "../../features/auth/auth.service";
import { ISignInRequest } from "../../models/SignInRequest";
import { User } from "../../models/User";
import { getUser } from "../../features/users/user.service";
import { useNavigate } from "react-router-dom";

interface ILoginRequest {
  data: ISignInRequest;
  setError: (val: any) => void;
  setLoading: (val: boolean) => void;
}

const authContext = createContext({
  login: (props: ILoginRequest) => {},
  logout: () => {},
  authenticated: true,
  checkAuthenticated: () => {},
  user: { username: "", email: "" } as User | undefined,
});

export const AuthProvider = ({ children }: any) => {
  const auth = useAuthProvider();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

const useAuthProvider = () => {
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User>();

  const navigate = useNavigate();

  useEffect(() => {
    isAuthenticated();
  }, []);

  useEffect(() => {
    authenticated && getUser().subscribe((res) => setUser(res));
  }, [authenticated]);

  function login({ data, setError, setLoading }: ILoginRequest) {
    signIn(data, setError, setLoading).subscribe((res) => {
      isAuthenticated();
      setLoading(false);
      navigate("/");
    });
  }

  const logout = () => {
    signOut().subscribe((res) => {
      isAuthenticated();
      window.location.reload();
    });
  };

  const isAuthenticated = () => {
    return getAuth2().subscribe({
      next: (res) => {
        setAuthenticated(res.status === 200);
      },
      error: (error) => {
        setAuthenticated(false);
      },
    });
  };

  const checkAuthenticated = () => {
    isAuthenticated();
  };

  return {
    login,
    logout,
    authenticated,
    checkAuthenticated,
    user,
  };
};
