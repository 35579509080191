import React, { useState } from "react";
import { useAuth } from "../../../utils/context/auth-context";
import { useNavigate } from "react-router-dom";
import { ISignInRequest } from "../../../models/SignInRequest";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
} from "@mui/material";
import { Field, Form } from "react-final-form";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ActionButton } from "../../../components/ui/buttons/action-button";

export const AdminSignInForm = () => {
  const [error, setError] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const auth = useAuth();
  const navigate = useNavigate();

  const login = (loginData: ISignInRequest) => {
    setLoading(true);
    auth.login({ data: loginData, setError, setLoading });
    navigate("/");
  };

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Form
        onSubmit={login}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <Field name="username">
                {({ input }) => (
                  <TextField
                    color="secondary"
                    autoComplete="on"
                    size="small"
                    fullWidth
                    label="Username"
                    required
                    {...input}
                  />
                )}
              </Field>
              <Field name="password">
                {({ input }) => (
                  <TextField
                    size="small"
                    color="secondary"
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    required
                    {...input}
                    InputProps={{
                      endAdornment: showPassword ? (
                        <IconButton
                          title="visibility-on"
                          aria-label="visibility-on"
                          size="small"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {" "}
                          <Visibility fontSize="inherit" />{" "}
                        </IconButton>
                      ) : (
                        <IconButton
                          title="visibility-off"
                          aria-label="visibility-off"
                          size="small"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {" "}
                          <VisibilityOff fontSize="inherit" />{" "}
                        </IconButton>
                      ),
                    }}
                  />
                )}
              </Field>
              {!!error && (
                <Alert severity={"error"}>
                  {" "}
                  Error: Wrong Username/Password{" "}
                </Alert>
              )}
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                type="submit"
              >
                {" "}
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Login"
                )}{" "}
              </Button>
            </Box>
          </form>
        )}
      ></Form>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <ActionButton
          title={"Forgot Password?"}
          onClick={() => navigate("/reset")}
        />
      </Box>
    </Box>
  );
};
