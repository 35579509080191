import { API } from "../../environment/api";
import { httpClient } from "../../utils/http/httpClient";
import { catchError, map, throwError } from "rxjs";

import { ErrorMap } from "../../models/ErrorMap";

export function postPdf(file: any) {
  return httpClient
    .post(API.upload.post, file)
    .pipe(map((res) => res.data as { id: string }));
}

export function postMultiPdf(
  file: any,
  setError: (err: ErrorMap) => void,
  catchErr: any,
) {
  return httpClient
    .post(API.upload.postMultipart, file, { authenticated: true })
    .pipe(
      map((res) => res.data),
      catchError((err) => {
        setError({
          statusCode: err.response.status,
          message: err.message,
          statusText: err.response.statusText,
          persistent: true,
        });
        catchErr(err);
        return throwError(err);
      }),
    );
}

export function createPdf(setError: (err: ErrorMap) => void, catchErr: any) {
  return httpClient.post(API.create, {}, { authenticated: true }).pipe(
    map((res) => res.data),
    catchError((err) => {
      setError({
        statusCode: err.response.status,
        message: err.message,
        statusText: err.response.statusText,
        persistent: true,
      });
      catchErr(err);
      return throwError(err);
    }),
  );
}
