import { EltrpModal } from "../../../components/ui/modal/eltrp-modal";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Field, Form } from "react-final-form";
import {
  Alert,
  Box,
  Button,
  Collapse,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  GlobalChangeFields,
  GlobalChangeFieldsMap,
  GlobalChangeTypes,
} from "../types/global-change-fields";
import { useMemo, useState } from "react";
import { GlobalChangeAutocomplete } from "./global-change-autocomplete";
import { GlobalChangeMultiAutocomplete } from "./global-change-multi-autocomplete";
import arrayMutators from "final-form-arrays";
import { globalChange } from "../global-change.service";
import { LoadingButton } from "../../../components/ui/buttons/loading-button";
import { useAlert } from "../../../utils/context/alert-context";
import { IGlobalChange, IGlobalChangeResponse } from "../types/GlobalChange";

export const GlobalChangeBox = ({ open, setOpen }: any) => {
  const [index, setIndex] = useState<string>("");
  const [response, setResponse] = useState<IGlobalChangeResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<boolean>(false);

  const indexValue = useMemo(() => {
    return index && GlobalChangeFieldsMap.get(index);
  }, [index]);

  const alertContext = useAlert();

  const onSubmit = (value: IGlobalChange) => {
    if (indexValue) {
      const change = {
        index: indexValue.name,
        term:
          indexValue.type === GlobalChangeTypes.TUPLE_NESTED
            ? value.term[1]
            : value.term,
        value: value.value,
        lead:
          indexValue.type === GlobalChangeTypes.TUPLE_NESTED
            ? value.term[0]
            : undefined,
      };

      setLoading(true);
      globalChange(change, alertContext.handleAlert, setLoading).subscribe(
        (res) => {
          setResponse(res);
          setLoading(false);
          setAlert(true);
          if (res.docs_processed === res.docs_total) {
            setIndex("");
          }
        },
      );
    }
  };

  const getResponseMessage = () => {
    let message = "";
    if (response) {
      message += `${response.docs_processed} of ${response.docs_total} documents processed.`;
    }
    if (response && response.metadata_updated > 0) {
      message += ` ${response.metadata_updated} of ${response.docs_total} documents updated.`;
    } else if (
      response &&
      response.metadata_updated === 0 &&
      response.metadata_noop === response.docs_total
    ) {
      message += ` No documents updated.`;
    }
    if (response && response.docs_processed < response.docs_total) {
      message +=
        " Notice that not all documents have been processed. To process more documents, please resubmit the change. ";
    }
    return message;
  };
  return (
    <EltrpModal
      title={"Global Change"}
      open={open}
      handleClose={() => {
        setOpen(false);
        setAlert(false);
      }}
    >
      <Grid2 container gap={1} minWidth={500} justifyContent={"center"}>
        <Box textAlign={"center"}>
          Global change for ELTRP documents. Changes are applied to all
          published and unpublished documents.
        </Box>
        <Form
          onSubmit={onSubmit}
          mutators={{ ...arrayMutators }}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={1}
                minWidth={300}
              >
                <Field name={"index"} render={() => null} />
                <Field name={"index"}>
                  {({ input }) => (
                    <FormControl>
                      <FormLabel color={"secondary"}>Index</FormLabel>
                      <Select
                        size={"small"}
                        onChange={(event) => {
                          setIndex(event.target.value);
                          input.onChange(event.target.value);
                        }}
                        value={index}
                        required={true}
                        color={"secondary"}
                      >
                        {Object.entries(GlobalChangeFields).map(
                          ([key, value]) => (
                            <MenuItem value={key}>{value.label}</MenuItem>
                          ),
                        )}
                      </Select>
                    </FormControl>
                  )}
                </Field>
                {indexValue && (
                  <Field
                    name={"term"}
                    subscription={{ active: true, value: true }}
                  >
                    {({ input, meta }) => (
                      <FormControl>
                        <FormLabel color={"secondary"}>From</FormLabel>
                        {indexValue.type !== GlobalChangeTypes.TUPLE_NESTED && (
                          <GlobalChangeAutocomplete
                            termId={indexValue.name}
                            active={meta.active}
                            input={input}
                          />
                        )}
                        {indexValue.type === GlobalChangeTypes.TUPLE_NESTED &&
                          indexValue.lead && (
                            <GlobalChangeMultiAutocomplete
                              termId={indexValue.name}
                              lead={indexValue.lead}
                              active={meta.active}
                              input={input}
                            />
                          )}
                      </FormControl>
                    )}
                  </Field>
                )}
                {indexValue && (
                  <Field
                    name={"value"}
                    subscription={{ active: true, value: true }}
                    validate={(value) =>
                      value?.trim().length ? undefined : "Must not be empty"
                    }
                  >
                    {({ input }) => (
                      <FormControl>
                        {" "}
                        <FormLabel color={"secondary"}> To </FormLabel>
                        <TextField
                          size={"small"}
                          value={input.value}
                          onChange={input.onChange}
                          required={true}
                          color={"secondary"}
                        />{" "}
                      </FormControl>
                    )}
                  </Field>
                )}
                <Box display={"flex"} justifyContent={"right"} gap={1}>
                  <Button
                    color={"secondary"}
                    variant={"outlined"}
                    onClick={() => setIndex("")}
                  >
                    RESET
                  </Button>

                  <LoadingButton
                    title={"SUBMIT"}
                    loading={loading}
                    type={"submit"}
                    disabled={!values["value"]?.trim()}
                  />
                </Box>
              </Box>
              <Box mt={1}>
                {response && (
                  <Collapse in={alert}>
                    <Alert severity={"info"} onClose={() => setAlert(false)}>
                      {getResponseMessage()}
                    </Alert>
                  </Collapse>
                )}
              </Box>
            </form>
          )}
        />
      </Grid2>
    </EltrpModal>
  );
};
