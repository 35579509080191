import Grid2 from "@mui/material/Unstable_Grid2";

export const FieldArrayLayout = (props: {
  textField: any;
  button: any;
  name: string;
}) => {
  return (
    <Grid2 container justifyContent={"flex-start"} alignItems={"center"}>
      <Grid2 xs key={"TextGrid" + props.name}>
        {" "}
        {props.textField}{" "}
      </Grid2>
      <Grid2
        style={{ minWidth: "2rem", maxWidth: "10rem" }}
        key={"ButtonGrid" + props.name}
      >
        {" "}
        {props.button}{" "}
      </Grid2>
    </Grid2>
  );
};
