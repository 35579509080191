import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";

export const useParamsStable = (field: string) => {
    const params = useParams();
    const [stable, setStable] = useState<string>();
    useEffect(() => {
        if(params && params[field] && params[field] !== stable) {
            setStable(params[field])
        }
    }, [params, stable, field])
    return stable;
}