import { ActionButton } from "../../../components/ui/buttons/action-button";
import { Print } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

export const PrintArticle = () => {
  const navigate = useNavigate();

  const printArticle = () => {
    navigate("print");
  };

  return (
    <Box>
      <ActionButton
        title={"Print"}
        startIcon={<Print />}
        onClick={printArticle}
      />
    </Box>
  );
};
