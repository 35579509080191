import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Input,
} from "@mui/material";

export const NewsletterForm = () => {
  const submitForm = () => {
    const newsletterForm = document.forms.namedItem("newsletterForm");
    newsletterForm && newsletterForm.submit();
    newsletterForm && newsletterForm.reset();
  };
  return (
    <form
      method="post"
      action="https://ui.ungpd.com/Api/Subscriptions/a7afb5f2-6c34-4006-b3e9-a7053ea9d2c0"
      id="form-3f76c8ee-0360-48a7-8f2b-bd1eeecda1c8"
      data-gtm-form-interact-id="0"
      target={"_blank"}
      name={"newsletterForm"}
    >
      <input type="hidden" name="DefaultCountryCode" value="+46" />
      <input
        type="hidden"
        name="ListIds"
        id="listId-74c95239-80d6-453d-9fe3-f5f6e04ccc3e"
        value="74c95239-80d6-453d-9fe3-f5f6e04ccc3e"
      />
      <h2>Sign up for our newsletter!</h2>
      <p>
        Welcome to subscribe to ELTRP. Through the newsletter, you'll gain
        access to the latest reports uploaded to the research portal.
      </p>
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <FormControl>
          <FormLabel htmlFor={"Contact[Name]"} color={"secondary"}>
            {" "}
            <b> Name:</b>{" "}
          </FormLabel>
          <Input
            style={{ maxWidth: "500px" }}
            type="text"
            id="Contact[Name]"
            name="Contact[Name]"
            color={"secondary"}
          />{" "}
        </FormControl>
        <FormControl>
          <FormLabel htmlFor={"Contact[Email]"} color={"secondary"}>
            {" "}
            <b> Email:</b>{" "}
          </FormLabel>
          <Input
            style={{ maxWidth: "500px" }}
            type="email"
            name="Contact[Email]"
            id="Contact[Email]"
            required={true}
            color={"secondary"}
            data-gtm-form-interact-field-id="1"
          />{" "}
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="Contact[Industry]" color={"secondary"}>
            {" "}
            <b> Industry:</b>{" "}
          </FormLabel>
          <Input
            style={{ maxWidth: "500px" }}
            type="text"
            required={true}
            id="Contact[Industry]"
            name="Contact[Industry]"
            color={"secondary"}
          />{" "}
        </FormControl>
        <FormControl>
          <span>
            Your information will not be shared with third parties. For more
            information,{" "}
            <a
              href="https://eltrp.org/data-policy"
              target="_blank"
              rel="noreferrer"
            >
              please read our privacy policy.
            </a>{" "}
            You can unsubscribe from the newsletter at any time.
          </span>
        </FormControl>
        <FormControl>
          <FormControlLabel
            htmlFor="consentText"
            control={<Checkbox color={"secondary"} required={true} />}
            label={
              <span>I would like to subscribe to the ELTRP newsletter.</span>
            }
            style={{ marginRight: 0, marginLeft: "-3px" }}
            value={"I would like to subscribe to the ELTRP newsletter."}
          />
        </FormControl>
        <Box display={"flex"} justifyContent={"right"}>
          <Button
            type="submit"
            variant={"contained"}
            color={"secondary"}
            onClick={submitForm}
          >
            Subscribe
          </Button>
        </Box>
      </Box>
    </form>
  );
};
