export const PresentationFieldList = (props: {
  metadata?: string[];
  title: string;
}) => {
  const { metadata, title } = props;
  return metadata && metadata.length > 0 ? (
    <span>
      <b> {title}: </b> {metadata?.join("; ")} <br />
    </span>
  ) : (
    <> </>
  );
};
