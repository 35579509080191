import { Button, ButtonProps, Typography } from "@mui/material";
import React from "react";
import { theme } from "../../../utils/theme/theme";

interface IHeaderButtonProps extends ButtonProps {
  title: string;
  icon: React.ReactNode;
}

export const HeaderButton = (props: IHeaderButtonProps) => {
  const { title, icon, ...rest } = props;
  return (
    <Button
      startIcon={props.icon}
      disableRipple
      sx={{
        color: theme.palette.secondary.main,
        ":hover": { color: "black" },
        svg: { marginTop: "-1px" },
      }}
      {...rest}
    >
      <Typography>{title} </Typography>
    </Button>
  );
};
