import { Field, Form } from "react-final-form";
import { Box, TextField } from "@mui/material";
import { StepperProps } from "../types/StepperProps";
import { confirmAccount, resendCode } from "../../users/user.service";
import { Fragment, useState } from "react";
import { ActionButton } from "../../../components/ui/buttons/action-button";
import { useAlert } from "../../../utils/context/alert-context";
import { LoadingButton } from "../../../components/ui/buttons/loading-button";

export const ConfirmSignUpForm = ({ setStep }: StepperProps) => {
  const [resend, setResend] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const alert = useAlert();

  const submit = (value: any) => {
    setLoading(true);
    confirmAccount(
      value.username,
      value.code,
      setLoading,
      alert.handleAlert,
    ).subscribe((res) => {
      setLoading(false);
      setStep((prevState) => prevState + 1);
    });
  };

  const resendConfirmation = (value: any) => {
    setLoading(true);
    resendCode(value.username, setLoading, alert.handleAlert).subscribe(
      (res) => {
        setLoading(false);
        setResend(false);
      },
    );
  };

  return (
    <Fragment>
      {resend ? (
        <Form
          onSubmit={resendConfirmation}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Field name={"username"}>
                  {({ input }) => (
                    <TextField
                      size="small"
                      fullWidth
                      label={"Email"}
                      color={"secondary"}
                      required
                      {...input}
                    />
                  )}
                </Field>
                <LoadingButton
                  title={"Resend Code"}
                  type={"submit"}
                  loading={loading}
                />
              </Box>
            </form>
          )}
        ></Form>
      ) : (
        <Form
          onSubmit={submit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Field name={"username"}>
                  {({ input }) => (
                    <TextField
                      size="small"
                      fullWidth
                      label={"Email"}
                      color={"secondary"}
                      required
                      {...input}
                    />
                  )}
                </Field>
                <Field name={"code"}>
                  {({ input }) => (
                    <TextField
                      size="small"
                      fullWidth
                      label={"Confirmation code"}
                      color={"secondary"}
                      required
                      {...input}
                    />
                  )}
                </Field>
                <LoadingButton
                  type={"submit"}
                  title={"Confirm Account"}
                  loading={loading}
                />
                <ActionButton
                  title={"Resend confirmation code"}
                  onClick={() => setResend(true)}
                />
              </Box>
            </form>
          )}
        ></Form>
      )}
    </Fragment>
  );
};
