import { useEffect, useMemo, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { EltrpSlider } from "../../../../components/ui/animations/eltrp-slider";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { SearchResponse } from "../../../../models/SearchResult";
import { simpleSearch } from "../../../search/search.service";
import { ResultPagination } from "../../../../components/pagination/result-pagination";
import { MetadataTypes } from "../../types/metadata-types";
import { useParamsStable } from "../../../../hooks/use-params-stable";
import { ResultBox } from "../../../result/result-box";
import { useAlert } from "../../../../utils/context/alert-context";

interface IMetadataLink {
  metadata: string;
  metadataType: MetadataTypes;
}

export const MetadataLink = (props: IMetadataLink) => {
  const { metadata, metadataType } = props;

  const id = useParamsStable("id");

  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const [results, setResults] = useState<SearchResponse>();

  const alert = useAlert();

  const value = useMemo(() => results?.hits.total.value, [results]);

  const filters = useMemo(
    () => [`${metadataType}:${metadata}`],
    [metadata, metadataType],
  );

  useEffect(() => {
    const from = searchParams.get("from") || "0";
    const size = searchParams.get("size") || "5";

    simpleSearch("", filters, from, size).subscribe((res) => {
      setResults(res);
    });
  }, [searchParams, alert.handleAlert, filters]);

  useEffect(() => {
    setOpen(false);
  }, [id]);

  const openModal = () => {
    searchParams.set("size", "5");
    searchParams.set("from", "0");
    window.history.replaceState({}, "", `${location.pathname}?size=5&from=0`);
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
    window.history.replaceState({}, "", location.pathname);
  };

  const getTitle = () => {
    switch (metadataType) {
      case MetadataTypes.author:
        return "Author";
      case MetadataTypes.organization:
        return "Organisation";
      default:
        return;
    }
  };

  const getMessage = () => {
    switch (metadataType) {
      case MetadataTypes.author:
        return value === 1
          ? "No more articles by this author"
          : "More articles by this author";
      case MetadataTypes.organization:
        return value === 1
          ? "No more articles by this organisation"
          : "More articles by this organisation";
      default:
        return;
    }
  };

  return (
    <Box>
      <Typography
        onClick={openModal}
        sx={{
          ":hover": { textDecoration: "underline" },
          cursor: "pointer",
          width: "fit-content",
        }}
      >
        {metadata}
      </Typography>
      <EltrpSlider open={open} close={closeModal} title={getTitle()}>
        <Box pl={2} pr={2}>
          <h1> {metadata}</h1>
          <i> {getMessage()} </i>
          <Divider />
        </Box>
        <Box pl={2} pr={2}>
          {value === 1 ? (
            <span>
              <h3>
                {" "}
                Do you know any articles related to the {getTitle()} {metadata}?
              </h3>
              <p>
                Try suggesting them to ELTRP! <br />
                Reach out to us with your suggestion!
              </p>
              <p>
                Read more under our <Link to={"/contact"}> contact page</Link>.
              </p>
            </span>
          ) : (
            <></>
          )}
        </Box>
        <Box className={"searchResultMetadata"} p={2}>
          <ResultBox results={results?.hits.docs} exclude={id} />
        </Box>
        <Box m={2}>
          <ResultPagination value={value} noFill={true} />
        </Box>
      </EltrpSlider>
    </Box>
  );
};
