import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Dispatch, SetStateAction } from "react";

interface VisibilityProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

export const PasswordVisibility = ({
  visible,
  setVisible,
}: VisibilityProps) => {
  return visible ? (
    <IconButton
      title="visibility-on"
      aria-label="visibility-on"
      size="small"
      onClick={() => setVisible(!visible)}
    >
      {" "}
      <Visibility fontSize="inherit" />{" "}
    </IconButton>
  ) : (
    <IconButton
      title="visibility-off"
      aria-label="visibility-off"
      size="small"
      onClick={() => setVisible(!visible)}
    >
      {" "}
      <VisibilityOff fontSize="inherit" />{" "}
    </IconButton>
  );
};
