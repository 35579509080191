import { useState } from "react";
import { Field } from "react-final-form";
import { Checkbox, FormControlLabel } from "@mui/material";

export const CheckBox = (props: { fieldName: string; label: string }) => {
  const [checked, setChecked] = useState<boolean>();
  return (
    <Field name={props.fieldName} type="checkbox">
      {({ input, meta }) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              color="secondary"
              onClick={() => setChecked(!checked)}
              {...input}
            />
          }
          label={props.label}
          sx={{ m: 1 }}
        />
      )}
    </Field>
  );
};
