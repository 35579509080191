import Grid2 from "@mui/material/Unstable_Grid2";
import { Box, Divider } from "@mui/material";

export const DictionaryElement = (props: {
  letterT: any;
  dictionaryJSON: any;
}) => {
  return (
    <>
      <Grid2 xs={12}>
        <h2 id={props.letterT}>
          <Divider> {props.letterT} </Divider>
        </h2>
      </Grid2>

      {Object.keys(props.dictionaryJSON)
        .filter((k) => k.startsWith(props.letterT))
        .map((k) => {
          return (
            <Grid2
              xs={10}
              sm={5}
              md={4}
              lg={3}
              display="box"
              justifyContent="center"
              id={k}
              key={k}
              className="dictionaryElem"
            >
              <h3> {k} </h3>
              <Divider
                variant="middle"
                sx={{ display: { xs: "none", sm: "flex" } }}
              />
              <Box display="inline-block" textAlign={"justify"}>
                <small> {props.dictionaryJSON[k]}</small>
              </Box>
            </Grid2>
          );
        })}
    </>
  );
};
