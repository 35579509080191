import Grid2 from "@mui/material/Unstable_Grid2";
import { Pagination } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { PageSizeOptions } from "./page-size-options";

export const ResultPagination = (props: {
  value?: number;
  noFill?: boolean;
}) => {
  const { value, noFill } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState<number>(1);

  const size = useMemo(() => Number(searchParams.get("size")), [searchParams]);
  const from = useMemo(() => Number(searchParams.get("from")), [searchParams]);

  useEffect(() => {
    setPage(Math.ceil(from / (size || 1)) + 1);
  }, [size, from]);

  useEffect(() => {
    if (!searchParams.get("size") && noFill !== true) {
      searchParams.set("size", "10");
      setSearchParams(searchParams);
    }

    if (!searchParams.get("from") && noFill !== true) {
      searchParams.set("from", "0");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams, noFill]);

  const count = useMemo(
    () => (value && !!size ? Math.ceil(value / size) : 1),
    [value, size],
  );

  const handleChange = (event: any, value: any) => {
    searchParams.set("from", ((value - 1) * size).toString());
    setPage(value);
    setSearchParams(searchParams);
  };

  const onChange = (event: any) => {
    searchParams.set("size", event.target.value.toString());
    searchParams.set("from", "0");
    setPage(1);
    setSearchParams(searchParams);
  };

  return (
    <Grid2 container justifyContent={"center"} alignItems={"center"}>
      <Grid2
        xs={12}
        justifyContent={"space-between"}
        sx={{
          display: { xs: "none", sm: "flex" },
        }}
      >
        <Pagination
          page={page}
          count={count}
          onChange={handleChange}
          boundaryCount={1}
        />
        <PageSizeOptions message="Page Size" value={size} onChange={onChange} />
      </Grid2>
      <Grid2
        xs={12}
        justifyContent={"space-between"}
        sx={{ display: { xs: "flex", sm: "none" } }}
      >
        <Pagination
          page={page}
          count={count}
          onChange={handleChange}
          boundaryCount={0}
          siblingCount={0}
        />
        <PageSizeOptions value={size} onChange={onChange} />
      </Grid2>
    </Grid2>
  );
};
