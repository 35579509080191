import { useEffect } from "react";
import { EltrpPage } from "../../layout/wrappers/eltrp-page";
import { EltrpHeader } from "../../layout/wrappers/eltrp-header";
import { EltrpBody } from "../../layout/wrappers/eltrp-body";
import { EltrpText } from "../../layout/wrappers/eltrp-text";

export const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <EltrpPage>
      <EltrpHeader title={"About"} />
      <EltrpBody>
        <EltrpText>
          <h2> About ELTRP </h2>
          <p>
            End-of-life tyre research portal (ELTRP) is the world's first
            research portal covering reports and articles on recycled tyre
            rubber. It is a society-supporting research portal aimed at enabling
            access to published research about the material in areas such as
            chemical content, performance, material usage, health and
            environment, and products. ELTRP is open to everyone and is intended
            to be effectively used by innovators, entrepreneurs, media,
            academics, authorities, municipalities, legislators, and more.
          </p>
          <p>
            Recycled tyre rubber is a well-research material with a wide range
            of research reports and articles. The idea of ELTRP, however,
            emerged after identifying a lack of accessibility and a
            long-standing need to gather available research in one unified
            database.
          </p>
          <p>
            With support from international tyre recycling organisations,
            Swedish Tyre Recycling Association (“SDAB”), has taken the
            initiative to implement and realize the portal for recycled tyre
            rubber. With ELTRP’s support, it becomes possible to use recycled
            material in a safe and secure manner for humans and the environment,
            based on the properties inherent in the material. The hope is also
            that ELTRP will continue to build trust in recycled tyre rubber and
            elevate the tyre industry as a serious and reliable sector. The goal
            is for ELTRP to become the definitive database for consolidated
            research on recycled tyre rubber.
          </p>
          <p>
            ELTRP is maintained by the Swedish tyre recycling association (SDAB
            and welcomes contributors of relevant research in the field, with
            the goal of developing the research portal into the definitive
            database for consolidated research on recycled tyre rubber. The
            selection process has a focus on reports and articles that have
            broader relevance for the public.
          </p>
          <p>
            Don’t hesitate to contact{" "}
            <a href={"mailto: info@eltrp.org"}>info@eltrp.org</a> if you have
            any questions about ELTRP, want to contribute with reports and
            articles or report if there is you encounter any issue with the
            portal.
          </p>
        </EltrpText>
      </EltrpBody>
    </EltrpPage>
  );
};
