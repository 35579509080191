import { Field } from "react-final-form";
import { TextField } from "@mui/material";

export const SingleField = (props: {
  fieldName: string;
  label: string;
  placeholder?: string;
}) => {
  return (
    <Field name={props.fieldName}>
      {({ input, meta }) => (
        <TextField
          color="secondary"
          size="small"
          multiline
          margin="normal"
          variant="outlined"
          placeholder={props.placeholder}
          fullWidth
          label={props.label}
          InputLabelProps={{ shrink: true }}
          {...input}
        />
      )}
    </Field>
  );
};
