export const API = {
  article: {
    get: "/api/v1/data/doc/{id}",
    update: "/api/v1/data/doc/{id}/metadata",
    attachment: {
      get: "/api/v1/data/docContent/{id}",
    },
  },
  auth: {
    login: "/api/v1/auth/sign-in",
    revoke: "/api/v1/auth/revoke",
    auth: "/api/v1/auth",
    refresh: "/api/v1/auth/refresh",
    logout: "/api/v1/auth/sign-out",
  },
  search: "/api/v1/data/search",
  autocomplete: "/api/v1/terms/{termId}",
  upload: {
    post: "/api/v1/data/attachment",
    postMultipart: "/api/v1/data/attachmentMultipart",
  },
  create: "/api/v1/data/metadata",
  env: "/api/v1/env",
  dictionary: "/api/v1/data/dictionary",
  map: "/api/v1/data/worldmap",
  qualityControl: {
    csv: {
      articles: "/api/v1/qualityControl/csv/articles.csv",
      aggregations: "/api/v1/qualityControl/csv/aggregations/{agg}.csv",
      links: "/api/v1/qualityControl/csv/links.csv",
    },
  },
  globalChange: "/api/v1/data/change",
  basket: {
    update: "/api/v1/basket",
    get: "/api/v1/basket/{basketId}/search",
    show: "/api/v1/basket/show",
  },
  user: {
    get: "/api/v1/auth/user",
    password: {
      change: "/api/v1/auth/change-password",
      forgot: "/api/v1/auth/forgot-password",
      reset: "/api/v1/auth/reset-password",
    },
    signUp: {
      create: "/api/v1/auth/sign-up-user",
      confirm: "/api/v1/auth/confirm-sign-up",
      resend: "/api/v1/auth/resend-code",
    },
    delete: "/api/v1/auth/delete",
  },
  favourites: "/api/v1/favourites",
  tags: "/api/v1/favourites/tags",
};
