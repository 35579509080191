import { useSearchParams } from "react-router-dom";
import { useMemo, useState } from "react";
import { Checkbox, Fade, IconButton } from "@mui/material";
import { Add, ExpandLess, ExpandMore, Remove } from "@mui/icons-material";
import { Aggregation } from "../../models/Aggregation";
import { fieldNameMapping } from "./types/field-name-mapping";

interface IAggregationProps {
  aggregation: Aggregation;
}

export const AggregationList = ({ aggregation }: IAggregationProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(fieldNameMapping[aggregation.field].open);
  const [numVisible, setNumVisible] = useState(3);

  const checked = useMemo(
    () => searchParams.getAll(aggregation.field),
    [searchParams, aggregation.field],
  );

  const selectAggs = (value: string) => {
    if (!checked.includes(value)) {
      searchParams.append(aggregation.field, value);
      searchParams.set("from", "0");
      setSearchParams(searchParams);
    } else {
      searchParams.delete(aggregation.field);
      searchParams.set("from", "0");
      checked
        .filter((c) => c !== value)
        .forEach((c) => searchParams.append(aggregation.field, c));
      setSearchParams(searchParams);
    }
  };

  return (
    <li style={{ listStyle: "none" }}>
      <span
        style={{ display: "flex" }}
        onClick={() => {
          setOpen(!open);
          setNumVisible(5);
        }}
      >
        <IconButton disableRipple size="small">
          {open ? <Remove fontSize="inherit" /> : <Add fontSize="inherit" />}
        </IconButton>
        <h3>
          {" "}
          {`${fieldNameMapping[aggregation.field].name}` +
            (checked.length > 0 ? ` (${checked.length})` : "")}{" "}
        </h3>
      </span>

      <Fade in={open} unmountOnExit>
        <ul className="insideList">
          {aggregation.buckets
            .filter((b) => checked.includes(b.key))
            .map((b) => (
              <li
                style={{ listStyle: "none" }}
                key={b.key}
                onClick={() => selectAggs(b.key)}
              >
                <div className="listItem">
                  <Checkbox
                    size="small"
                    color="secondary"
                    checked={checked.includes(b.key)}
                  />
                  <span> {b.key + " (" + b.doc_count + ")"} </span>{" "}
                </div>{" "}
              </li>
            ))}
          {aggregation.buckets
            .filter((b) => !checked.includes(b.key))
            .slice(0, numVisible)
            .map((b) => (
              <li
                style={{ listStyle: "none" }}
                key={b.key}
                onClick={() => selectAggs(b.key)}
              >
                <div className="listItem">
                  <Checkbox
                    size="small"
                    color="secondary"
                    checked={checked.includes(b.key)}
                  />
                  <span> {b.key + " (" + b.doc_count + ")"} </span>{" "}
                </div>{" "}
              </li>
            ))}
          {aggregation.buckets.length > 3 ? (
            numVisible < aggregation.buckets.length ? (
              <div
                className="expand"
                onClick={() =>
                  numVisible > aggregation.buckets.length + 5
                    ? setNumVisible(aggregation.buckets.length)
                    : setNumVisible(numVisible + 5)
                }
                style={{
                  display: "flex",
                  paddingLeft: "1.75rem",
                  alignItems: "center",
                }}
              >
                <ExpandMore fontSize="inherit" /> <i> Show More </i>
              </div>
            ) : (
              <div
                className="expand"
                onClick={() => setNumVisible(3)}
                style={{
                  display: "flex",
                  paddingLeft: "1.75rem",
                  alignItems: "center",
                }}
              >
                <ExpandLess fontSize="inherit" /> <i> Show Less </i>
              </div>
            )
          ) : (
            <></>
          )}
        </ul>
      </Fade>
    </li>
  );
};
