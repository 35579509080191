import Grid2 from "@mui/material/Unstable_Grid2";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

export const DesktopFooter = () => {
  const navigator = useNavigate();
  return (
    <Grid2
      container
      xs={12}
      xl={11}
      xlOffset={1}
      flexDirection={"row"}
      pl={4}
      pr={4}
    >
      <Grid2 xs={3} display={"flex"} flexDirection={"column"} gap={1}>
        <span>
          {" "}
          <Typography> HELP </Typography>
        </span>
        <Box display={"flex"} flexDirection={"column"}>
          <Link to={"/how-to-search"}>How to search</Link>
          <Link to={"/data-policy"}>ELTRP Data protection policy</Link>
        </Box>
      </Grid2>
      <Grid2 xs={3} display={"flex"} flexDirection={"column"} gap={1}>
        <Typography> ELTRP </Typography>
        <Box display={"flex"} flexDirection={"column"}>
          <Link to={"/about"}> About </Link>
          <Link to={"/contact"}> Contact us </Link>
          <Link to={"/copyright"}>Copyright</Link>
          <Link to={"/dictionary"}> Dictionary</Link>
          <Link to={"/favourites/?from=0&size=10"}> Favourite Articles</Link>
        </Box>
      </Grid2>
      <Grid2
        xs={6}
        xl={5}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"right"}
        textAlign={"right"}
        gap={1}
      >
        <Typography> Join the ELTRP newsletter!</Typography>
        <Box>
          <Button
            variant={"contained"}
            color={"secondary"}
            onClick={() => navigator("/newsletter")}
          >
            SUBSCRIBE{" "}
          </Button>
        </Box>
      </Grid2>
    </Grid2>
  );
};
