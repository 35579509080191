import { ReactNode } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Divider } from "@mui/material";

interface IEltrpSearchResult {
  aggregations: ReactNode;
  results: ReactNode;
  hits: number;
}

export const EltrpSearchResults = (props: IEltrpSearchResult) => {
  const { aggregations, results, hits } = props;
  return (
    <Grid2 container>
      <Grid2
        xs={12}
        md={4}
        lg={3}
        sx={{ display: { xs: "none", md: "inline" } }}
      >
        {aggregations}
      </Grid2>
      <Divider
        flexItem
        orientation="vertical"
        style={{
          display: hits > 0 ? "flex" : "none",
        }}
      />
      <Grid2 xs={12} md={7.8} lg={8.8} pl={{ md: "2rem" }}>
        {results}
      </Grid2>
    </Grid2>
  );
};
