import { EltrpModal } from "../../../components/ui/modal/eltrp-modal";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Box, Button, FormControl, TextField } from "@mui/material";
import { Field, Form } from "react-final-form";
import { search } from "../../search/search.service";
import { useState } from "react";
import { LoadingButton } from "../../../components/ui/buttons/loading-button";
import { ResultList } from "./result-list";
import { Article } from "../../../models/Article";
import { useAlert } from "../../../utils/context/alert-context";

export const NewsletterBox = ({ open, setOpen }: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [results, setResults] = useState<Article[]>();

  const alert = useAlert();
  const onSubmit = (value: any) => {
    setLoading(true);
    search(
      "",
      [],
      alert.handleAlert,
      setLoading,
      "0",
      value.number,
      "-PUBDATE",
    ).subscribe((res) => {
      setResults(res.hits.docs);
      setLoading(false);
    });
  };

  const copyResult = () => {
    const result = document.getElementById("searchResultList");
    try {
      result && navigator.clipboard.writeText(result.innerHTML);
    } catch (e) {
      alert.handleAlert({
        statusCode: 418,
        message: "Could not copy result",
      });
    }
  };

  return (
    <EltrpModal
      title={"Newsletter"}
      open={open}
      handleClose={() => {
        setOpen(false);
        setResults([]);
      }}
    >
      <Grid2
        container
        gap={2}
        justifyContent={"center"}
        flexDirection={"column"}
      >
        <Box textAlign={"center"}>
          Get the <i> N </i> latest published articles from ELTRP to use in the
          newsletter. Click on <b>COPY RESULT</b> to copy all the latest report
          or copy them manually.
        </Box>
        <Grid2>
          <Form
            onSubmit={onSubmit}
            render={(props) => (
              <form onSubmit={props.handleSubmit}>
                <Box display={"flex"} justifyContent={"center"} gap={1}>
                  <Field name={"number"}>
                    {(props) => (
                      <FormControl>
                        <TextField
                          style={{ minWidth: 250 }}
                          type={"number"}
                          InputProps={{ inputProps: { min: 0 } }}
                          color={"secondary"}
                          variant={"outlined"}
                          name={props.input.name}
                          value={props.input.value}
                          label={"Number of latest articles"}
                          size={"small"}
                          fullWidth={true}
                          onChange={props.input.onChange}
                        />
                      </FormControl>
                    )}
                  </Field>
                  <LoadingButton
                    type={"submit"}
                    title={"GET"}
                    loading={loading}
                    size={"small"}
                  />
                </Box>
              </form>
            )}
          />
        </Grid2>
        <Grid2>
          <Box maxHeight={"40vh"} overflow={"auto"}>
            <ResultList results={results} forNewsletter={true} />
          </Box>
        </Grid2>
        <Box display={"flex"} justifyContent={"right"}>
          <Button
            variant={"contained"}
            color={"secondary"}
            onClick={copyResult}
          >
            COPY RESULT
          </Button>
        </Box>
      </Grid2>
    </EltrpModal>
  );
};