export enum Aggregations {
  "LANGUAGE" = "Language",
  "CONTRIBUTOR_lead" = "Contributor",
  "LINK_service" = "Service",
  "ORGANIZATION_lead" = "Organisation",
  "PERIODICAL_lead" = "Periodical",
  "ATTACHMENT_TYPE" = "Attachment type",
  "AUTHOR" = "Author",
  "IDENTIFIER_type" = "Identifier",
  "PUBLISHED" = "Published",
  "SERIES_lead" = "Series",
  "COUNTRY" = "Country",
  "YEAR" = "Year",
  "CONTRIBUTOR_type" = "Contributor type",
  "LINK_access" = "Access type",
  "SUBJECT" = "Keywords",
  "CONFERENCE_lead" = "Conference",
}
