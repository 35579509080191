import { EltrpAuthPage } from "../../../layout/wrappers/eltrp-auth-page";
import { SignInForm } from "../components/sign-in-form";
import { FormWrapper } from "../components/form-wrapper";
import { useEnv } from "../../../utils/context/env-context";
import { AdminSignInForm } from "../components/admin-sign-in-form";

export const SignInPage = () => {
  const env = useEnv();
  return (
    <EltrpAuthPage>
      {env.env === "ADMIN" ? (
        <FormWrapper title={"Admin sign in"}>
          <AdminSignInForm />
        </FormWrapper>
      ) : (
        <FormWrapper
          title={"Sign in"}
          subtitle={"Sign in to your ELTRP account!"}
        >
          <SignInForm />
        </FormWrapper>
      )}
    </EltrpAuthPage>
  );
};
