import { createContext, useContext, useEffect, useState } from "react";
import { getEnv } from "../../features/auth/auth.service";

enum Environments {
  ADMIN = "ADMIN",
  PUBLIC = "PUBLIC",
}

const envContext = createContext({
  env: Environments.PUBLIC,
});

export const EnvProvider = ({ children }: any) => {
  const env = useEnvProvider();

  return <envContext.Provider value={env}>{children}</envContext.Provider>;
};

export const useEnv = () => {
  return useContext(envContext);
};

const useEnvProvider = () => {
  const [env, setEnv] = useState<Environments>(Environments.PUBLIC);

  useEffect(() => {
    getEnv().subscribe((res) => setEnv(res.data.env));
  }, []);

  return { env };
};
