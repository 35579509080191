import { Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { DesktopFooter } from "./desktop-footer";
import { MobileFooter } from "./mobile-footer";

export const PageFooterContent = () => {
  return (
    <Box pt={2} pb={4}>
      {" "}
      <Grid2 container display={{ xs: "none", sm: "flex" }}>
        <DesktopFooter />
      </Grid2>
      <Grid2 container display={{ xs: "flex", sm: "none" }}>
        <MobileFooter />
      </Grid2>{" "}
    </Box>
  );
};
