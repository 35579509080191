import { ActionButton } from "../../../components/ui/buttons/action-button";
import Grid2 from "@mui/material/Unstable_Grid2";
import { ContentCopy, Report } from "@mui/icons-material";
import { useState } from "react";
import { EltrpModal } from "../../../components/ui/modal/eltrp-modal";
import { Alert, Box, IconButton, Snackbar, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

import { ShareAlert } from "../../../models/ShareAlert";

export const ReportAProblem = (props: { articleId?: string }) => {
  const { articleId } = props;
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState<ShareAlert>();

  const copySubject = () => {
    const data = `Problem Report ELTRP ${articleId}`;
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(data)
        .then(() =>
          setAlert({
            open: true,
            message: "Copied content to clipboard",
            severity: "success",
          }),
        )
        .catch(() =>
          setAlert({
            open: true,
            message: `Error trying to copy content. Please copy content manually.`,
            severity: "error",
          }),
        );
    } else if (!navigator.clipboard) {
      setAlert({
        open: true,
        message: `Error trying to copy content. Please copy content manually.`,
        severity: "error",
      });
    }
  };

  return (
    <Grid2 container display={"flex"} justifyContent={"right"}>
      <ActionButton
        title={"Report a problem"}
        startIcon={<Report />}
        onClick={() => setOpen(!open)}
      />
      <EltrpModal
        title={"Report a problem"}
        open={open}
        handleClose={() => setOpen(false)}
      >
        <Box
          textAlign={"center"}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <h3> Is there a problem with the article? </h3>
          <p>
            {" "}
            Please reach out to us with the issue. Please email us at{" "}
            <a href={"mailto: info@eltrp.org"}>info@eltrp.org</a> with the
            subject shown below and describe the issue.{" "}
          </p>
          <Box
            style={{
              boxShadow: "0px 2px 8px 0px rgba(99, 99, 99, 0.4)",
              width: "fit-content",
              padding: 8,
            }}
          >
            <h4 style={{ margin: 0 }}>
              Problem Report ELTRP {articleId}{" "}
              <Tooltip title={"Copy"}>
                <IconButton
                  onClick={copySubject}
                  size={"small"}
                  sx={{ display: { xs: "none", md: "inline" } }}
                >
                  <ContentCopy />
                </IconButton>
              </Tooltip>
            </h4>{" "}
          </Box>
          <p>
            {" "}
            Read more about how to contact ELTRP and report problems under{" "}
            <Link to={"/contact"}> Contact us</Link>.
          </p>
        </Box>
      </EltrpModal>
      {alert && (
        <Snackbar
          autoHideDuration={alert.severity === "success" ? 6000 : undefined}
          open={alert?.open}
          onClose={() => setAlert(undefined)}
        >
          <Alert severity={alert.severity}>{alert?.message}</Alert>
        </Snackbar>
      )}
    </Grid2>
  );
};
