import { httpClient } from "../../utils/http/httpClient";
import { API } from "../../environment/api";
import { catchError, map, throwError } from "rxjs";
import { SearchResponse } from "../../models/SearchResult";

import { ErrorMap } from "../../models/ErrorMap";

export function search(
  query: string,
  filters: string[] | [],
  setError: (err: ErrorMap) => void,
  setLoading: (val: boolean) => void,
  from?: string,
  size?: string,
  sortOrder?: string,
) {
  return httpClient
    .get(API.search, {
      queryParams: {
        query: query || "",
        filter: [...filters, "dummyvalue"],
        from: from,
        size: size,
        sortOrder: sortOrder,
      },
      authenticated: true,
    })
    .pipe(
      map((res) => res.data as SearchResponse),
      catchError((err) => {
        setError({
          statusCode: err.statusCode,
          message: err.message,
          extra: err.extra,
        });
        setLoading(false);
        return throwError(err);
      }),
    );
}

export function simpleSearch(
  query: string,
  filters: string[] | [],
  from?: string,
  size?: string,
) {
  return httpClient
    .get(API.search, {
      queryParams: {
        query: query || "",
        filter: [...filters, "dummyvalue"],
        from: from,
        size: size,
      },
      authenticated: true,
    })
    .pipe(map((res) => res.data as SearchResponse));
}
