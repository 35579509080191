import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useMemo,
} from "react";
import { useAuth } from "./auth-context";
import { ErrorMap } from "../../models/ErrorMap";

const alertContext = createContext({
  alert: {
    message: "",
    extra: null,
    statusText: "",
    open: false,
    persistent: false,
  },
  setAlert: (() => null) as Dispatch<any>,
  handleAlert: (err: ErrorMap) => {},
});
export const AlertProvider = ({ children }: PropsWithChildren) => {
  const alert = useAlertProvider();
  return (
    <alertContext.Provider value={alert}>{children}</alertContext.Provider>
  );
};

export const useAlert = () => {
  return useContext(alertContext);
};

const useAlertProvider = () => {
  const [alert, setAlert] = React.useReducer(
    (state: any, newValue: any) => ({ ...state, ...newValue }),
    {
      message: "",
      extra: null,
      statusText: "",
      open: false,
      persistent: false,
    },
  );

  const auth = useAuth();

  const handleAlert = useMemo(
    () => (error: ErrorMap) => {
      if (error.statusCode === 401) {
        auth.checkAuthenticated();
      }

      setAlert({
        message: error.message,
        extra: error.extra,
        statusText: error.statusText,
        open: true,
        persistent: error.persistent,
      });
    },
    [auth],
  );

  return { alert, setAlert, handleAlert };
};
