import Grid2 from "@mui/material/Unstable_Grid2";
import { PropsWithChildren } from "react";

interface IHeaderProps extends PropsWithChildren {
  title?: string;
}
export const EltrpHeader = (props: IHeaderProps) => {
  const { title, children } = props;
  return (
    <Grid2 container pt={2} pb={2} className={"eltrp-header"}>
      <Grid2 xs={12} sm={8} smOffset={2}>
        {title && (
          <div className={"title"}>
            <h1>{title}</h1>
          </div>
        )}
        {children}
      </Grid2>
    </Grid2>
  );
};
