import { PropsWithChildren } from "react";
import { Box, Typography } from "@mui/material";

interface IFormWrapper extends PropsWithChildren {
  title: string;
  subtitle?: string;
}

export const FormWrapper = ({ title, subtitle, children }: IFormWrapper) => {
  return (
    <Box
      display={"flex"}
      gap={2}
      flexDirection={"column"}
      maxWidth={"90%"}
      width={500}
    >
      <Typography variant={"h3"}>{title}</Typography>
      <Typography variant={"subtitle2"}>{subtitle}</Typography>
      {children}
    </Box>
  );
};
