export interface GlobalChangeField {
  name: string;
  label: string;
  type: GlobalChangeTypes;
  lead?: string;
}

interface GlobalChangeObject {
  [key: string]: GlobalChangeField;
}

export enum GlobalChangeTypes {
  NON_REPEATABLE,
  REPEATABLE,
  TUPLE_NESTED,
  TUPLE_NOT_NESTED,
}

export const GlobalChangeFields: GlobalChangeObject = {
  type: {
    name: "TYPE",
    label: "Type",
    type: GlobalChangeTypes.NON_REPEATABLE,
  },
  language: {
    name: "LANGUAGE",
    label: "Language",
    type: GlobalChangeTypes.NON_REPEATABLE,
  },
  year: {
    name: "YEAR",
    label: "Year",
    type: GlobalChangeTypes.NON_REPEATABLE,
  },
  author: {
    name: "AUTHOR",
    label: "Author",
    type: GlobalChangeTypes.REPEATABLE,
  },
  country: {
    name: "COUNTRY",
    label: "Country",
    type: GlobalChangeTypes.REPEATABLE,
  },
  subject: {
    name: "SUBJECT",
    label: "Keyword",
    type: GlobalChangeTypes.REPEATABLE,
  },
  organization: {
    name: "ORGANIZATION",
    label: "Organisational Unit",
    type: GlobalChangeTypes.TUPLE_NESTED,
    lead: "ORGANIZATION_lead",
  },
  organization_lead: {
    name: "ORGANIZATION_lead",
    label: "Organisation",
    type: GlobalChangeTypes.TUPLE_NOT_NESTED,
  },
  series_lead: {
    name: "SERIES_lead",
    label: "Series",
    type: GlobalChangeTypes.TUPLE_NOT_NESTED,
  },
  conference_lead: {
    name: "CONFERENCE_lead",
    label: "Conference",
    type: GlobalChangeTypes.TUPLE_NOT_NESTED,
  },
  link_access: {
    name: "LINK_access",
    label: "Link Access",
    type: GlobalChangeTypes.TUPLE_NOT_NESTED,
  },
  link_service: {
    name: "LINK_service",
    label: "Link Service",
    type: GlobalChangeTypes.TUPLE_NOT_NESTED,
  },
  identifier_type: {
    name: "IDENTIFIER_type",
    label: "Identifier Type",
    type: GlobalChangeTypes.TUPLE_NOT_NESTED,
  },
  contributor_lead: {
    name: "CONTRIBUTOR_lead",
    label: "Contributor",
    type: GlobalChangeTypes.TUPLE_NOT_NESTED,
  },
  contributor_type: {
    name: "CONTRIBUTOR_type",
    label: "Contributor Type",
    type: GlobalChangeTypes.TUPLE_NOT_NESTED,
  },
};

export const GlobalChangeFieldsMap = new Map(
  Object.entries(GlobalChangeFields),
);
