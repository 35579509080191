import { httpClient } from "../../utils/http/httpClient";
import { API } from "../../environment/api";
import { catchError, map, throwError } from "rxjs";
import { User } from "../../models/User";
import { ChangePassword } from "./types/ChangePassword";
import { ErrorMap } from "../../models/ErrorMap";

export function getUser() {
  return httpClient.get(API.user.get).pipe(map((res) => res.data as User));
}

export function changePassword(
  data: ChangePassword,
  setLoading: (val: any) => any,
  handleAlert: (val: any) => any,
) {
  return httpClient.post(API.user.password.change, { ...data }).pipe(
    catchError((err) => {
      setLoading(false);
      handleAlert(err as ErrorMap);
      return throwError(err);
    }),
  );
}

export function forgotPassword(
  username: string,
  setLoading: (val: any) => any,
  handleAlert: (val: any) => any,
) {
  return httpClient.post(API.user.password.forgot, { username: username }).pipe(
    catchError((err) => {
      setLoading(false);
      handleAlert(err as ErrorMap);
      return throwError(err);
    }),
  );
}

export function resetPassword(
  username: string,
  confirmationCode: string,
  password: string,
  setLoading: (val: any) => any,
  handleAlert: (val: any) => any,
) {
  return httpClient
    .post(API.user.password.reset, {
      username: username,
      confirmationCode: confirmationCode,
      password: password,
    })
    .pipe(
      catchError((err) => {
        setLoading(false);
        handleAlert(err as ErrorMap);
        return throwError(err);
      }),
    );
}

export function signUp(
  email: string,
  password: string,
  setLoading: (val: any) => any,
  setAlert: (val: any) => any,
) {
  return httpClient
    .post(API.user.signUp.create, {
      username: email,
      email: email,
      password: password,
    })
    .pipe(
      map((res) => res),
      catchError((err) => {
        setLoading(false);
        setAlert(err.message);
        return throwError(err);
      }),
    );
}

export function confirmAccount(
  username: string,
  code: string,
  setLoading: (val: any) => any,
  handleAlert: (val: any) => any,
) {
  return httpClient
    .post(API.user.signUp.confirm, {
      username: username,
      confirmationCode: code,
    })
    .pipe(
      catchError((err) => {
        setLoading(false);
        handleAlert(err as ErrorMap);
        return throwError(err);
      }),
    );
}

export function resendCode(
  username: string,
  setLoading: (val: any) => any,
  handleAlert: (val: ErrorMap) => any,
) {
  return httpClient.post(API.user.signUp.resend, { username: username }).pipe(
    catchError((err) => {
      setLoading(false);
      handleAlert(err as ErrorMap);
      return throwError(err);
    }),
  );
}

export function deleteAccount(
  setLoading: (val: any) => any,
  handleAlert: (val: ErrorMap) => any,
) {
  return httpClient.post(API.user.delete, {}).pipe(
    catchError((err) => {
      setLoading(false);
      handleAlert(err as ErrorMap);
      return throwError(err);
    }),
  );
}
