import { createTheme } from "@mui/material";
import foot2 from "../../assets/images/foot2.jpg";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#E7DCAB",
      light: "#F5F1DC",
      dark: "#f9f7eb",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#4d4d4d",
      light: "#6d685f",
      dark: "#1d1a16",
      contrastText: "#ffffff",
    },
    text: {
      primary: "#000000",
      secondary: "#000000",
    },
    error: {
      main: "#dd88a9",
      contrastText: "#772243",
    },
    warning: {
      main: "#c45715",
    },
  },
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiChip: {
      defaultProps: {
        variant: "outlined",
      },

      styleOverrides: {
        root: {
          color: "#2e2c32",
          boxShadow: "0px 2px 2px 0px rgba(99, 99, 99, 0.1)",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {},
    },
    MuiInputBase: {
      defaultProps: {
        autoComplete: "off",
        spellCheck: false,
      },
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "rgba(109,104,95,0.1)",
            ":hover": {
              backgroundColor: "rgba(109,104,95,0.2)",
            },
          },
          ":hover": {
            backgroundColor: "rgba(109,104,95,0.2)",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          alignSelf: "start",
          padding: "0 0.5rem 0 0",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: (themeParams) => ({
        a: {
          color: "#2249b4",
          //color: "#524a38",
          //color: "#423c2e",
          textDecoration: "none",
          ":visited": {
            color: "#54278b",
          },
          ":hover": { textDecoration: "underline" },
        },
        "@media (max-width: 600px)": {
          small: { fontSize: 16 },
        },

        footer: {
          backgroundColor: themeParams.palette.primary.light,
          marginTop: "auto",
          fontSize: 14,
          a: {
            color: "black",
            ":visited": {
              color: "black",
            },
          },
          ".footerSmall": {
            fontSize: 16,
          },
        },
        ".dictionaryHeader": {
          ".letterActive": {
            cursor: "pointer",
            ":hover": {
              textDecoration: "underline",
            },
          },
          ".letterDisabled": {
            cursor: "default",
            color: "rgba(0,0,0,0.2)",
          },
        },
        ".dictionaryBody": {
          // margin: "0 0 2rem 0",
          "@media (max-width: 600px)": {
            small: {
              fontSize: 16,
            },
            h5: {
              fontSize: 18,
            },
          },
        },
        ".dictionaryElem": {
          "@media (min-width: 600px)": {
            padding: "1rem",
            borderRadius: 10,
            boxShadow: "0px 2px 8px 0px rgba(99, 99, 99, 0.2)",
            div: {
              padding: "1rem",
            },
          },
        },
        ".landingPageSearchOLD": {
          backgroundColor: themeParams.palette.primary.light,
          backgroundImage: `url(${foot2})`,
          backgroundSize: "100%",
          backgroundPosition: "center",
          boxShadow: "0px 2px 8px 0px rgba(99, 99, 99, 0.2)",
        },
        ".landingPageSearch": {
          backgroundColor: themeParams.palette.primary.light,
          boxShadow: "0px 2px 8px 0px rgba(99, 99, 99, 0.2)",
          h4: {
            margin: 0,
          },
        },
        ".landingPageLinks": {
          a: {
            textDecoration: "none",
            color: "black",
            ":hover": {
              textDecoration: "underline",
            },
          },
          h4: {
            marginTop: 0,
            marginBottom: 0,
          },
        },
        ".searchBar": {
          backgroundColor: themeParams.palette.primary.light,
          boxShadow: "0px 2px 8px 0px rgba(99, 99, 99, 0.2)",
          h4: {
            margin: 0,
          },
        },
        ".searchResult": {
          //margin: "0 0 2rem 0",
          //padding: " 0 0 1rem 0",
          ul: {
            listStyle: "none",
          },
          ".selectedAggs": {
            marginLeft: "2rem",
            marginTop: "1rem",
            "@media (max-width: 600px)": {
              marginLeft: "0.25rem",
            },
          },
          ".searchResultElement": {
            marginBottom: "2rem",
            ul: {
              paddingRight: "1rem",
            },
            h3: {
              fontSize: 20,
            },

            /*"@media (max-width: 600px)": {
                                                                                                                                                                                                                                                                                                                                                  ul: {
                                                                                                                                                                                                                                                                                                                                                    paddingLeft: "0.25rem",
                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                  li: {
                                                                                                                                                                                                                                                                                                                                                    fontSize: 18,
                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                  h3: {
                                                                                                                                                                                                                                                                                                                                                    fontSize: 22,
                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                },*/
          },
          "@media (max-width: 600px)": {
            borderStyle: "none",
            padding: "0%",
          },
        },
        ".searchAggregationSmall": {
          padding: "0",
          margin: "0",
          h4: {
            margin: "0.5rem 0 0.25rem 0",
            fontSize: 18,
          },
          ul: {
            padding: "0 0 0 1rem",
          },
          ".insideList": {
            li: {
              padding: "0.25rem ",
              fontSize: 18,
            },
          },
        },
        ".searchAggregation": {
          padding: "0",
          h3: {
            margin: "0.5rem 0 0.5rem 0",
          },
          ul: {
            padding: "0",
          },
          margin: "0 2rem 2rem 0",

          ".expand": {
            cursor: "pointer",
            display: "flex",
            color: themeParams.palette.secondary.main,
            ":hover": {
              //fontSize: 17,
              color: "black",
              textDecoration: "underline",
              transition: "1s ease-out",
            },
          },

          ".insideList": {
            li: {
              padding: "0 0 0 1.5rem",
            },
          },
          li: {
            margin: "0.5rem 0 0.5rem 0",
          },
          ".listItem": {
            display: "flex",
            margin: "0 0.5rem 0.5rem 0",
          },
        },

        ".relatedArticles": {
          a: {
            textDecoration: "none",
            color: "black",
            ":hover": {
              textDecoration: "underline",
            },
          },

          h4: {
            marginTop: 0,
            marginBottom: 0,
          },
        },
        ".editForm": {
          ".editGroup": {
            borderLeft: "solid",
            borderWidth: 5,
            borderColor: themeParams.palette.secondary.main,
            padding: "0.5rem",
            marginTop: "1rem",
          },
        },
        ".eltrp-header": {
          backgroundColor: themeParams.palette.primary.light,
          boxShadow: "0px 2px 8px 0px rgba(99, 99, 99, 0.2)",

          ".title": {
            minHeight: "10vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 8,
            h1: { fontSize: 28 },
            textAlign: "center",
          },
          ".summary": {
            padding: "1rem 1rem 1rem 1rem",
            span: { maxHeight: "1rem" },
            small: {
              lineHeight: "2rem",
            },
            ul: {
              margin: "0rem 0 0rem 0",
              fontSize: 16,
            },
          },
        },
        /* ".metadataHeader": {
                                                                                                                                                                                                                           backgroundColor: themeParams.palette.primary.light,
                                                                                                                                                                                                                           h2: { margin: 0 },
                                                                                                                                                                                                                         },*/
        ".searchResultMetadata": {
          ul: {
            listStyle: "none",
            paddingLeft: "16px",
          },
        },
      }),
    },
  },
});