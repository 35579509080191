import { Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { DesktopHeader } from "./desktop-header";
import { MobileHeader } from "./mobile-header";

export const PageHeaderContent = () => {
  return (
    <Box mt={1} mb={1}>
      {" "}
      <Grid2 container display={{ xs: "none", md: "flex" }}>
        <DesktopHeader />
      </Grid2>
      <Grid2 container display={{ xs: "flex", md: "none" }}>
        <MobileHeader />
      </Grid2>
    </Box>
  );
};
