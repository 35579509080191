import { createContext, useContext, useEffect, useState } from "react";
import { SavedArticles } from "../../models/SavedArticles";
import {
  getFavourites,
  getTags,
  updateFavourites,
  updateTags,
} from "../../features/save/save-article.service";
import { useAuth } from "./auth-context";
import {
  addMultipleToLocalStorage,
  addTagCategories,
  addToLocalStorage,
  getIdsFromLocalStorage,
  getIdsWithTagFromLocalStorage,
  getTags as getTagsFromLocal,
  getTagsCategories,
  hasLocalStorage,
  isInLocalStorage,
  removeFromLocalStorage,
  removeTagCategory,
  updateTags as updateTagsLocal,
} from "../storage/local-storage";

const favouritesContext = createContext({
  checkSaved: (val: string) => false as boolean,
  addFavourite: (val: string) => {},
  removeFavourite: (val: string) => {},
  checkIsFirstSave: () => true as boolean,
  getTagsFromArticle: (val: string) => [] as string[],
  updateTagsOnArticle: (val1: string, val2: string[]) => {},
  addTag: (val: string) => {},
  getTagList: () => [] as string[],
  removeTag: (val: string) => {},
  getArticleIds: () => [] as string[],
  getArticlesWithTag: (val: string) => [] as string[],
  addFavouritesChunk: (val1: string[], val2?: string) => {},
});

export const FavouritesProvider = ({ children }: any) => {
  const favourites = useFavouritesProvider();
  return (
    <favouritesContext.Provider value={favourites}>
      {" "}
      {children}
    </favouritesContext.Provider>
  );
};

export const useFavourites = () => {
  return useContext(favouritesContext);
};

export const useFavouritesProvider = () => {
  const [favourites, setFavourites] = useState<SavedArticles>();
  const [tags, setTags] = useState<string[]>([]);

  const auth = useAuth();
  
  useEffect(() => {
    auth.authenticated &&
      getFavourites().subscribe((res) => {
        setFavourites(new SavedArticles(JSON.stringify(res)));
      });
  }, [auth]);

  useEffect(() => {
    auth.authenticated && getTags().subscribe((res) => setTags(res.tags));
  }, [auth]);

  const updateFavouritesFile = () => {
    auth.authenticated &&
      favourites &&
      updateFavourites(favourites).subscribe();
  };

  const updateTagsFile = (newTags: string[]) => {
    auth.authenticated && newTags && updateTags(newTags).subscribe();
  };

  const checkIsFirstSave = () => {
    return auth.authenticated ? false : !hasLocalStorage();
  };

  const checkSaved = (articleId: string) => {
    return auth.authenticated
      ? !!favourites?.hasArticle(articleId)
      : isInLocalStorage(articleId);
  };

  const addFavourite = (articleId: string) => {
    if (auth.authenticated) {
      favourites?.addArticle(articleId);
      updateFavouritesFile();
    } else {
      addToLocalStorage(articleId);
    }
  };

  const removeFavourite = (articleId: string) => {
    if (auth.authenticated) {
      favourites?.removeArticle(articleId);
      updateFavouritesFile();
    } else {
      removeFromLocalStorage(articleId);
    }
  };

  const getArticleIds = () => {
    if (auth.authenticated) {
      return favourites?.getIds() || [];
    } else {
      return getIdsFromLocalStorage() || [];
    }
  };

  const getTagsFromArticle = (articleId: string) => {
    if (auth.authenticated) {
      return favourites?.getTags(articleId) || [];
    } else {
      return getTagsFromLocal(articleId) || [];
    }
  };

  const updateTagsOnArticle = (articleId: string, tags: string[]) => {
    if (auth.authenticated) {
      favourites?.updateTagsOnArticle(articleId, tags);
      updateFavouritesFile();
    } else {
      updateTagsLocal(articleId, tags);
    }
  };

  const addTag = (tag: string) => {
    if (auth.authenticated) {
      if (tags && !tags.includes(tag)) {
        setTags(tags.concat(tag));
        updateTagsFile(tags.concat(tag));
      } else {
        setTags([tag]);
        updateTagsFile([tag]);
      }
    } else {
      !getTagsCategories().includes(tag) && addTagCategories(tag);
    }
  };

  const removeTag = (tag: string) => {
    if (auth.authenticated) {
      const newTags = tags.filter((t) => t !== tag);
      setTags(newTags);
      updateTagsFile(newTags);
    } else {
      removeTagCategory(tag);
    }
  };

  const getTagList = () => {
    if (auth.authenticated) {
      return tags || [];
    } else {
      return getTagsCategories() || [];
    }
  };

  const getArticlesWithTag = (tag: string) => {
    if (auth.authenticated) {
      return favourites?.getIdsWithTag(tag) || [];
    } else {
      return getIdsWithTagFromLocalStorage(tag);
    }
  };

  const addFavouritesChunk = (ids: string[], tag?: string) => {
    if (auth.authenticated) {
      const savedIds = favourites?.getIds();
      ids.map((id) => {
        return savedIds?.includes(id)
          ? favourites?.updateTagsOnArticle(
              id,
              makeNewTagList(tag, favourites?.getTags(id) || []),
            )
          : favourites?.addArticleWithTag(id, tag);
      });
      updateFavouritesFile();
    } else {
      addMultipleToLocalStorage(ids, tag);
    }
    tag && addTag(tag);
  };

  const makeNewTagList = (tag?: string, tagList?: string[]) => {
    if (tag && tagList) {
      return tagList?.includes(tag) ? tagList : tagList.concat(tag);
    } else {
      return tag ? [tag] : [];
    }
  };
  return {
    checkSaved,
    addFavourite,
    removeFavourite,
    checkIsFirstSave,
    getTagsFromArticle,
    updateTagsOnArticle,
    addTag,
    getTagList,
    removeTag,
    getArticleIds,
    getArticlesWithTag,
    addFavouritesChunk,
  };
};
