import { forwardRef, PropsWithChildren, ReactElement, Ref } from "react";
import { Box, Dialog, Divider, IconButton, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { Close } from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { theme } from "../../../utils/theme/theme";

interface ISlider extends PropsWithChildren {
  open: boolean;
  close: () => void;
  title?: string;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const EltrpSlider = (props: ISlider) => {
  const { open, close, title, children } = props;

  return (
    <Dialog
      open={open}
      keepMounted={false}
      onClose={close}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          position: "fixed",
          top: 0,
          right: 0,
          m: 0,
          width: { xs: "85%", md: "45%" },
        },
      }}
      fullScreen={true}
    >
      <Box>
        <Grid2
          xs={12}
          display={"flex"}
          gap={1}
          sx={{ backgroundColor: theme.palette.primary.light }}
        >
          <IconButton onClick={close}>
            <Close />
          </IconButton>
          <h2> {title} </h2>
        </Grid2>
        <Divider />
        {children}
      </Box>
    </Dialog>
  );
};