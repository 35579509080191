import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Link, useNavigate } from "react-router-dom";
import React, { useMemo, useState } from "react";
import {
  Help,
  Info,
  Mail,
  Menu as MenuIcon,
  MenuBook,
  MenuOpen,
  QuestionAnswer,
  Search,
  Star,
} from "@mui/icons-material";
import { UserMenu } from "../../features/users/components/user-menu";
import logo3 from "../../assets/logos/logo3.png";

export const MobileHeader = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const navigate = useNavigate();

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Grid2 container xs={12}>
      <Grid2
        xs={2}
        display={"flex"}
        textAlign="left"
        justifyContent={"left"}
        pl={1}
        pb={1.5}
      >
        <Link to="/">
          <img
            alt={"ELTRP LOGO"}
            src={logo3}
            style={{ width: "min(50px,100%)", marginBottom: "-18px" }}
          />
        </Link>
      </Grid2>
      <Grid2
        xs={10}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"right"}
        pr={1}
      >
        <IconButton
          size={"large"}
          style={{ padding: "0.5rem" }}
          onClick={() => navigate("/search/?query=&from=0&size=10")}
        >
          <Search fontSize={"large"} />
        </IconButton>
        <IconButton
          onClick={handleClick}
          size={"large"}
          style={{ padding: "0.5rem" }}
        >
          {" "}
          {open ? (
            <MenuOpen fontSize={"large"} />
          ) : (
            <MenuIcon fontSize={"large"} />
          )}{" "}
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuList sx={{ minWidth: 300 }}>
            <MenuItem onClick={() => navigate("/how-to-search")}>
              <ListItemIcon>
                <Help fontSize={"small"} />
              </ListItemIcon>
              <ListItemText> How To Search</ListItemText>
            </MenuItem>

            <MenuItem onClick={() => navigate("/newsletter")}>
              <ListItemIcon>
                <Mail fontSize={"small"} />
              </ListItemIcon>
              <ListItemText>Newsletter</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => navigate("/contact")}>
              <ListItemIcon>
                <QuestionAnswer fontSize={"small"} />
              </ListItemIcon>
              <ListItemText>Contact us</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => navigate("/about")}>
              <ListItemIcon>
                <Info fontSize={"small"} />
              </ListItemIcon>
              <ListItemText>About</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => navigate("/dictionary")}>
              <ListItemIcon>
                <MenuBook fontSize={"small"} />
              </ListItemIcon>
              <ListItemText> Dictionary </ListItemText>
            </MenuItem>
            <MenuItem onClick={() => navigate("/favourites")}>
              <ListItemIcon>
                <Star fontSize={"small"} />
              </ListItemIcon>
              <ListItemText> Favourite Articles </ListItemText>
            </MenuItem>
          </MenuList>
        </Menu>
        <UserMenu fontSize={"large"} />
      </Grid2>
    </Grid2>
  );
};
