import { useEffect, useState } from "react";
import { Article } from "../models/Article";
import {
  getArticle,
  getArticleWithAttachment,
} from "../features/article/article.service";
import { useParamsStable } from "../hooks/use-params-stable";
import Grid2 from "@mui/material/Unstable_Grid2";
import { ConnectWithoutContact, Public, PublicOff } from "@mui/icons-material";
import { Box, Divider, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { getDictionary } from "../features/dictionary/dictionary.service";
import { LinkElement } from "../features/article/components/fields/link-element";
import { PresentationFieldTwofold } from "../features/article/components/fields/presentation-field-twofold";
import { PresentationField } from "../features/article/components/fields/presentation-field";
import { PresentationFieldList } from "../features/article/components/fields/presentation-field-list";
import { DictionaryChips } from "../features/dictionary/components/dictionary-chips";
import { EltrpPage } from "../layout/wrappers/eltrp-page";
import { EltrpHeader } from "../layout/wrappers/eltrp-header";
import { EltrpBody } from "../layout/wrappers/eltrp-body";
import { EltrpText } from "../layout/wrappers/eltrp-text";
import { EltrpLoading } from "../layout/wrappers/eltrp-loading";
import { ShareArticle } from "../features/share/share-article";
import { PrintArticle } from "../features/article/print/print-article";
import { MetadataLinkField } from "../features/article/components/metadata-link/metadata-link-field";
import { MetadataTypes } from "../features/article/types/metadata-types";
import { MetadataLink } from "../features/article/components/metadata-link/metadata-link";
import { ReportAProblem } from "../features/article/components/report-a problem";
import { useAlert } from "../utils/context/alert-context";
import { useEnv } from "../utils/context/env-context";
import { useAuth } from "../utils/context/auth-context";
import { FavouriteControls } from "../features/save/components/favourite-controls";

export const ArticlePage = () => {
  const id = useParamsStable("id");
  const [article, setArticle] = useState<Article>();
  const [dictionaryJSON, setDictionaryJSON] = useState({});
  const [loading, setLoading] = useState(false);

  const alert = useAlert();
  const env = useEnv();
  const auth = useAuth();

  useEffect(() => {
    getDictionary(setLoading).subscribe((a) => setDictionaryJSON(a));
  }, []);

  useEffect(() => {
    setLoading(true);
    if (id && env.env === "ADMIN" && auth.authenticated) {
      getArticleWithAttachment(id, alert.handleAlert, setLoading).subscribe(
        (a) => {
          setLoading(false);
          setArticle(a);
        },
      );
    } else if (id && env.env === "PUBLIC") {
      getArticle(id, alert.handleAlert, setLoading).subscribe((a) => {
        setLoading(false);
        setArticle(a);
      });
    }
  }, [id, env, auth, alert]);

  return (
    <EltrpPage>
      <EltrpLoading loading={loading} />
      <EltrpHeader>
        <Grid2 className="summary">
          <Grid2 xs={12}>
            <PresentationField
              title={"Comment by ELTPR"}
              metadata={article?.metadata?.commentExt}
            />
            <PresentationField
              title={"Internal Comment"}
              metadata={article?.metadata?.commentInt}
            />
            <h1>
              {article?.metadata?.titleEn
                ? article.metadata.titleEn
                : "No Title Provided"}
            </h1>
            <Grid2 container>
              <Grid2 xs={12} style={{ display: "flex", flexWrap: "wrap" }}>
                {article?.metadata?.author &&
                article.metadata.author.length > 0 ? (
                  <MetadataLinkField
                    metadataList={article.metadata.author}
                    metadataType={MetadataTypes.author}
                  />
                ) : (
                  <></>
                )}
              </Grid2>

              <Grid2 xs={12}>
                {article?.metadata?.type ? article?.metadata.type : <></>},{" "}
                {article?.metadata?.year ? article?.metadata.year : <></>}
              </Grid2>
            </Grid2>
          </Grid2>

          {article?.metadata?.link && article.metadata.link.length > 0 ? (
            <Grid2 xs={12} style={{ overflowWrap: "break-word" }}>
              <h3> Access </h3>
              <ul>
                {article?.metadata.link.map((url) => (
                  <li key={url[1]}>
                    <LinkElement url={url} />
                  </li>
                ))}
              </ul>
            </Grid2>
          ) : (
            <></>
          )}

          <Grid2 xs={12} justifyContent={"right"} textAlign="right" mt={1}>
            {article?.metadata?.titleTranslated ? (
              <small> Title translated by ELTRP </small>
            ) : (
              ""
            )}
          </Grid2>

          <Grid2
            display={{ xs: "none", md: "flex" }}
            justifyContent={"right"}
            alignItems={"center"}
            gap={2}
          >
            <ShareArticle articleId={article?._id} />
            {article?._id && <FavouriteControls articleId={article?._id} />}
            <PrintArticle />
          </Grid2>
          <Grid2
            display={{ xs: "flex", md: "none" }}
            justifyContent={"right"}
            alignItems={"center"}
            gap={2}
          >
            {article?._id && <FavouriteControls articleId={article?._id} />}
            <PrintArticle />
          </Grid2>

          {env.env === "ADMIN" && (
            <Grid2 mt={2}>
              {article?.metadata?.published ? (
                <Typography variant={"subtitle2"} color={"secondary"}>
                  <Box
                    display={"flex"}
                    gap={1}
                    alignItems={"center"}
                    justifyContent={"right"}
                  >
                    {" "}
                    <Public fontSize={"small"} /> PUBLISHED{" "}
                  </Box>
                </Typography>
              ) : (
                <Typography variant={"subtitle2"} color={"secondary"}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"right"}
                    gap={1}
                  >
                    <PublicOff fontSize={"small"} /> NOT PUBLISHED
                  </Box>
                </Typography>
              )}{" "}
            </Grid2>
          )}
        </Grid2>
      </EltrpHeader>
      <EltrpBody>
        {auth.authenticated && article?.properties ? (
          <Box>
            <Divider>
              <h4> ARTICLE PROPERTIES </h4>
            </Divider>
            <EltrpText>
              <div>
                <span>
                  <b> Filename: </b>{" "}
                  {article?.properties?.inputFilename
                    ? article.properties.inputFilename
                    : "No filename"}
                  <br />
                </span>
                <span>
                  <b> Attachment: </b>
                  {article?.attachment && article.attachment.content_type
                    ? article.attachment.content_type
                    : "No attachment"}
                  <br />
                </span>
                <span>
                  <b> Created: </b>
                  {article?.properties?.creDate
                    ? article.properties.creDate
                    : "No Creation Date"}
                  <br />
                </span>
                <span>
                  <b> Modified: </b>
                  {article?.properties?.modDate
                    ? article.properties.modDate
                    : "No Modification Date"}
                  <br />
                </span>
                <span>
                  <b> Published: </b>
                  {article?.properties?.pubDate
                    ? article.properties.pubDate
                    : "No Publication Date"}
                  <br />
                </span>
              </div>
            </EltrpText>
            <Divider>
              {" "}
              <h4> EXTRACTED PDF TEXT </h4>{" "}
            </Divider>
            <div>
              {article?.attachment?.content?.length ? (
                <Paper>
                  <Box p={1} maxHeight={"20rem"} overflow={"auto"}>
                    {article.attachment.content}
                  </Box>
                </Paper>
              ) : (
                <EltrpText>No extracted PDF text found.</EltrpText>
              )}
            </div>
          </Box>
        ) : (
          <></>
        )}

        {article?.properties && article?.metadata?.relation ? (
          <Divider
            flexItem
            variant="middle"
            style={{
              margin: "0.5rem 0 0.5rem 0",
            }}
          />
        ) : (
          <></>
        )}
        {article?.metadata?.relation && article.metadata.relation.length > 0 ? (
          <Grid2
            className="relatedArticles"
            textAlign="center"
            justifyContent={"center"}
            pt={2}
          >
            <Link
              to={`/search/?from=0&size=10&sortOrder=RELATION&RELATION_lead=${article.metadata.relation[0][0]}`}
            >
              <ConnectWithoutContact color="secondary" fontSize="large" />
              <br />
              <Typography variant={"button"}> Related Articles</Typography>
            </Link>
            <span style={{ lineHeight: "1rem" }}>
              <p style={{ fontSize: 12 }}>
                This article is part of a collection of article. <br />
                Click to see related articles.
              </p>
            </span>
          </Grid2>
        ) : (
          <></>
        )}
        {article?.metadata?.relation &&
        article.metadata.relation.length > 0 &&
        article.metadata?.abstractEn ? (
          <Divider
            flexItem
            variant="middle"
            style={{
              margin: "0.5rem 0 0.5rem 0",
            }}
          />
        ) : (
          <></>
        )}
        <EltrpText>
          <Grid2 xs={12}>
            {article?.metadata?.abstractEn ? (
              <>
                <h3> Abstract [en] </h3>
                <p> {article?.metadata.abstractEn} </p>
              </>
            ) : (
              <></>
            )}
          </Grid2>
          <Grid2 xs={12} justifyContent={"right"} textAlign="right">
            {article?.metadata?.abstractTranslated ? (
              <small> Abstract translated by ELTRP </small>
            ) : (
              ""
            )}
          </Grid2>
          <Grid2 xs={12}>
            {article?.metadata?.abstractOther ? (
              <>
                <h3> Abstract [other] </h3>
                <p> {article?.metadata.abstractOther} </p>
              </>
            ) : (
              <></>
            )}
            {article?.metadata?.summary ? (
              <>
                <h3> Summary </h3>
                <p> {article?.metadata.summary} </p>
              </>
            ) : (
              ""
            )}
          </Grid2>
        </EltrpText>

        {article?.metadata?.subject && article.metadata.subject.length > 0 ? (
          <Divider>
            <h4> KEYWORDS </h4>
          </Divider>
        ) : (
          <></>
        )}

        <EltrpText>
          <Grid2 display={"box"}>
            <DictionaryChips
              subjects={article?.metadata?.subject}
              dictionaryJSON={dictionaryJSON}
            />
          </Grid2>
        </EltrpText>

        {article?.metadata ? (
          <Divider>
            <h4> ARTICLE INFORMATION </h4>
          </Divider>
        ) : (
          <></>
        )}

        <EltrpText>
          <Grid2 className={"articleInfo"}>
            <PresentationField
              title={"Title other"}
              metadata={article?.metadata?.titleOther}
            />
            {!!article?.metadata.author.length && (
              <PresentationField title={"Author"}>
                <MetadataLinkField
                  metadataList={article?.metadata.author}
                  metadataType={MetadataTypes.author}
                />
              </PresentationField>
            )}
            {article?.metadata.organization &&
            article.metadata.organization.length > 0 ? (
              <span style={{ display: "flex", flexWrap: "wrap" }}>
                <b> Organisation:&nbsp;</b>{" "}
                {article.metadata.organization?.map((s, index) => (
                  <>
                    {!!index && <>;&nbsp;</>}
                    <MetadataLink
                      metadata={s[0]}
                      metadataType={MetadataTypes.organization}
                    />
                    {s[1] && `, ${s[1]}`}
                  </>
                ))}
                <br />
              </span>
            ) : (
              <></>
            )}
            <PresentationField
              title={"Original language"}
              metadata={article?.metadata?.language}
            />
            <PresentationFieldList
              title={"Country"}
              metadata={article?.metadata?.country}
            />
            <PresentationFieldTwofold
              title={"Periodical"}
              metadata={article?.metadata?.periodical}
            />
            <PresentationFieldTwofold
              title={"Series"}
              metadata={article?.metadata?.series}
            />
            <PresentationFieldTwofold
              title={"Conference"}
              metadata={article?.metadata?.conference}
            />
            <PresentationField
              title={"Year"}
              metadata={article?.metadata?.year}
            />
            {article?.metadata?.contributor &&
            article.metadata.contributor.length > 0 ? (
              <span>
                <b> Contributor: </b>
                {article?.metadata.contributor
                  ?.map((c) => c[0] + (c[1] !== null ? " (" + c[1] + ")" : ""))
                  .join("; ")}
                <br />
              </span>
            ) : (
              <></>
            )}
            <PresentationFieldTwofold
              title={"Identifier"}
              metadata={article?.metadata?.identifier}
            />
            <PresentationFieldTwofold
              title={"Related articles"}
              metadata={article?.metadata?.relation}
            />
          </Grid2>
        </EltrpText>
        <Grid2 mb={"2rem"}>
          <ReportAProblem articleId={article?._id} />
        </Grid2>
      </EltrpBody>
    </EltrpPage>
  );
};
