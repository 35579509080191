import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { Dispatch, ReactNode, SetStateAction } from "react";

interface IStep {
  label: string;
  component: ReactNode;
}

interface IStepperProps {
  steps: IStep[];
  finish: ReactNode;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
}

export const EltrpStepper = ({
  steps,
  finish,
  activeStep,
  setActiveStep,
}: IStepperProps) => {
  //const [activeStep, setActiveStep] = useState<number>(0);

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step, index) => {
          const stepProps: {
            completed?: boolean;
          } = {};

          return (
            <Step key={step.label} {...stepProps}>
              <StepLabel
                onClick={() => setActiveStep(index)}
                style={{ cursor: "pointer" }}
              >
                {step.label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box>
        {steps.map((step, index) => index === activeStep && step.component)}
        {activeStep === steps.length && finish}
      </Box>
    </Box>
  );
};
