import { PropsWithChildren } from "react";
import { Dialog, IconButton, Paper } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Close } from "@mui/icons-material";

interface IModalProps extends PropsWithChildren {
  title: string;
  open: boolean;
  handleClose: () => void;
}
export const EltrpModal = (props: IModalProps) => {
  const { title, open, handleClose, children } = props;
  return (
    <Dialog open={open}>
      <Paper sx={{ padding: "0rem 0.5rem 3rem 0.5rem" }}>
        <Grid2 container justifyContent={"right"}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid2>
        <Grid2 textAlign="center">
          <h2> {title} </h2>
        </Grid2>
        {children}
      </Paper>
    </Dialog>
  );
};
