import { useEffect } from "react";
import { EltrpBody } from "../../layout/wrappers/eltrp-body";
import { EltrpHeader } from "../../layout/wrappers/eltrp-header";
import { EltrpPage } from "../../layout/wrappers/eltrp-page";
import { EltrpText } from "../../layout/wrappers/eltrp-text";

export const Copyright = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <EltrpPage>
      <EltrpHeader title={"Copyright"} />
      <section className="copyright">
        <EltrpBody>
          <EltrpText>
            <h2> © Copyright </h2>
            <p>
              {" "}
              This website is aimed at facilitating access to relevant
              scientific research in the field of tyre end-of-life issues. It
              has been produced and published by the Swedish Tyre Recycling
              Association (“SDAB”) and is subject to copyright.
            </p>
            <p>
              The scientific papers, to which we link, are made available by
              their respective publisher and on the conditions governing the
              website where they appear. They are subject to copyright. Copying,
              citing, spreading or other use of texts and/or pictures must be
              cleared with the respective copyright holder.
            </p>
          </EltrpText>
        </EltrpBody>
      </section>
    </EltrpPage>
  );
};
