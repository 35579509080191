import { Field, Form } from "react-final-form";
import { Box, TextField } from "@mui/material";
import { useState } from "react";
import { PasswordVisibility } from "../../users/components/password-visibility";
import { StepperProps } from "../types/StepperProps";
import { signUp } from "../../users/user.service";
import { useAlert } from "../../../utils/context/alert-context";
import { LoadingButton } from "../../../components/ui/buttons/loading-button";

export const SignUpForm = ({ setStep }: StepperProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const alert = useAlert();

  const submit = (value: any) => {
    setLoading(true);
    signUp(
      value.email,
      value.password,
      setLoading,
      alert.handleAlert,
    ).subscribe((res) => {
      setStep((prevState) => prevState + 1);
      setLoading(false);
    });
  };
  return (
    <Form
      onSubmit={submit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <Field name={"email"}>
              {({ input }) => (
                <TextField
                  label={"Email"}
                  type={"email"}
                  size={"small"}
                  color={"secondary"}
                  fullWidth
                  {...input}
                />
              )}
            </Field>
            <Field name={"password"}>
              {({ input }) => (
                <TextField
                  label={"Password"}
                  color={"secondary"}
                  size={"small"}
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  {...input}
                  InputProps={{
                    endAdornment: (
                      <PasswordVisibility
                        visible={showPassword}
                        setVisible={setShowPassword}
                      />
                    ),
                  }}
                />
              )}
            </Field>
            <Field name={"confirm_password"}>
              {({ input }) => (
                <TextField
                  label={"Confirm Password"}
                  color={"secondary"}
                  size={"small"}
                  fullWidth
                  type={showConfirmPassword ? "text" : "password"}
                  {...input}
                  InputProps={{
                    endAdornment: (
                      <PasswordVisibility
                        visible={showConfirmPassword}
                        setVisible={setShowConfirmPassword}
                      />
                    ),
                  }}
                />
              )}
            </Field>
            <LoadingButton
              type={"submit"}
              title={"Sign up"}
              loading={loading}
            />
          </Box>
        </form>
      )}
    ></Form>
  );
};
