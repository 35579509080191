import Grid2 from "@mui/material/Unstable_Grid2";
import { Link, useNavigate } from "react-router-dom";
import ELTRP_Logo_Horizontal_for_white_background from "../../assets/logos/ELTRP_Logo_Horizontal_for_white_background.png";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material";
import {
  Help,
  Info,
  Mail,
  Menu as MenuIcon,
  MenuBook,
  MenuOpen,
  QuestionAnswer,
  Search,
  Star,
} from "@mui/icons-material";
import React, { useMemo, useState } from "react";
import { HeaderButton } from "../../components/ui/buttons/header-button";
import { UserMenu } from "../../features/users/components/user-menu";

export const DesktopHeader = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const navigate = useNavigate();

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid2 xs={12} container pl={4} pr={4}>
      <Grid2
        xlOffset={1}
        xs={3}
        xl={4}
        display="flex"
        justifyContent={"left"}
        alignItems={"center"}
      >
        <Link to="/">
          <img
            alt={"ELTRP LOGO"}
            src={ELTRP_Logo_Horizontal_for_white_background}
            style={{ width: "min(300px,30vw)" }}
          />
        </Link>
      </Grid2>
      <Grid2
        xs={7.5}
        xl={5}
        display="flex"
        justifyContent={"right"}
        alignItems={"center"}
        textAlign={"center"}
        gap={2}
      >
        <HeaderButton
          title={"Search"}
          icon={<Search />}
          onClick={() => navigate("/search/?query=&from=0&size=10")}
        />
        <HeaderButton
          title={"Dictionary"}
          icon={<MenuBook />}
          onClick={() => navigate("/dictionary")}
        />
        <HeaderButton
          title={"Favourites "}
          icon={<Star />}
          onClick={() => navigate("/favourites/?from=0&size=10")}
        />
      </Grid2>
      <Grid2
        xs={1.5}
        xl={1}
        display="flex"
        justifyContent={"right"}
        alignItems={"center"}
        textAlign={"center"}
        gap={2}
      >
        <UserMenu />

        <IconButton onClick={handleClick}>
          {" "}
          {open ? <MenuOpen /> : <MenuIcon />}{" "}
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuList sx={{ minWidth: 300 }}>
            <MenuItem onClick={() => navigate("/how-to-search")}>
              <ListItemIcon>
                <Help fontSize={"small"} />
              </ListItemIcon>
              <ListItemText> How To Search</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => navigate("/newsletter")}>
              <ListItemIcon>
                <Mail fontSize={"small"} />
              </ListItemIcon>
              <ListItemText>Newsletter</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => navigate("/contact")}>
              <ListItemIcon>
                <QuestionAnswer fontSize={"small"} />
              </ListItemIcon>
              <ListItemText>Contact us</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => navigate("/about")}>
              <ListItemIcon>
                <Info fontSize={"small"} />
              </ListItemIcon>
              <ListItemText>About</ListItemText>
            </MenuItem>
          </MenuList>
        </Menu>
      </Grid2>
    </Grid2>
  );
};
