import { LinearProgress } from "@mui/material";

export const EltrpLoading = (props: { loading: boolean }) => {
  return (
    <div style={{ height: "4px" }}>
      {" "}
      {props.loading ? <LinearProgress /> : null}{" "}
    </div>
  );
};
