import { Button, ButtonProps, CircularProgress } from "@mui/material";

interface ILoadingButtonProps extends ButtonProps {
  title: string;
  loading: boolean;
}
export const LoadingButton = (props: ILoadingButtonProps) => {
  const { title, loading, ...rest } = props;
  return (
    <Button color={"secondary"} variant={"contained"} {...rest}>
      {loading ? <CircularProgress size={24} color={"inherit"} /> : title}
    </Button>
  );
};
