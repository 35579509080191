import { Link, useNavigate } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Box, Button, Typography } from "@mui/material";

export const MobileFooter = () => {
  const navigator = useNavigate();

  return (
    <Grid2
      xs={12}
      display="flex"
      flexDirection={"column"}
      textAlign={"left"}
      p={2}
      gap={2}
    >
      <Box textAlign={"center"}>
        <Typography> Join the ELTRP newsletter!</Typography>
      </Box>
      <Button
        variant={"contained"}
        fullWidth
        color={"secondary"}
        onClick={() => navigator("/newsletter")}
      >
        SUBSCRIBE{" "}
      </Button>
      <Box display={"flex"} flexDirection={"column"}>
        <Link to="/data-policy"> ELTRP data protection policy </Link>
        <Link to="/copyright"> Copyright </Link>{" "}
        <Link to={"/contact"}>Contact us</Link>
        <Link to="/how-to-search"> How to search </Link>
        <Link to="/dictionary"> Dictionary </Link>
        <Link to="/about"> About</Link>
        <Link to="/favourites/?from=0&size=10"> Favourite Articles</Link>
        <Link to="/newsletter"> Newsletter </Link>
      </Box>
    </Grid2>
  );
};
