import { Box, TextField } from "@mui/material";
import { Field, Form } from "react-final-form";
import { resetPassword } from "../../users/user.service";
import { PasswordVisibility } from "../../users/components/password-visibility";
import { useState } from "react";
import { StepperProps } from "../types/StepperProps";
import { ActionButton } from "../../../components/ui/buttons/action-button";
import { useAlert } from "../../../utils/context/alert-context";
import { LoadingButton } from "../../../components/ui/buttons/loading-button";

export const ResetPasswordForm = ({ step, setStep }: StepperProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const alert = useAlert();

  const submit = (value: {
    username: string;
    code: string;
    new_password: string;
  }) => {
    setLoading(true);
    resetPassword(
      value.username,
      value.code,
      value.new_password,
      setLoading,
      alert.handleAlert,
    ).subscribe((res) => {
      setLoading(false);
      setStep((prevState) => prevState + 1);
    });
  };

  return (
    <Box>
      <Form
        onSubmit={submit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <Field name={"username"}>
                {({ input }) => (
                  <TextField
                    size={"small"}
                    label={"Email"}
                    color={"secondary"}
                    required
                    fullWidth
                    {...input}
                  />
                )}
              </Field>
              <Field name={"code"}>
                {({ input }) => (
                  <TextField
                    size={"small"}
                    label={"Reset Code"}
                    color={"secondary"}
                    required
                    fullWidth
                    {...input}
                  />
                )}
              </Field>
              <Field name={"new_password"}>
                {({ input }) => (
                  <TextField
                    size={"small"}
                    label={"New Password"}
                    color={"secondary"}
                    required
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    {...input}
                    InputProps={{
                      endAdornment: (
                        <PasswordVisibility
                          visible={showPassword}
                          setVisible={setShowPassword}
                        />
                      ),
                    }}
                  />
                )}
              </Field>

              <LoadingButton
                type={"submit"}
                title={"Reset Password"}
                loading={loading}
              />
              <ActionButton
                title={"Need a new reset code?"}
                onClick={() => setStep((prevState) => prevState - 1)}
              />
            </Box>
          </form>
        )}
      ></Form>
    </Box>
  );
};
