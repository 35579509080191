import Grid2 from "@mui/material/Unstable_Grid2";
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";

interface IPageSizeOptions {
  message?: string;
  value?: number;
  onChange: (event: any) => void;
}

export const PageSizeOptions = (props: IPageSizeOptions) => {
  const { message, value, onChange } = props;
  const sizeOptions = [5, 10, 25];
  return (
    <>
      <Grid2 container alignItems={"center"} gap={2}>
        <Grid2>
          <Typography variant={"button"}> {message || ""}</Typography>
        </Grid2>
        <Box display={"flex"} flexDirection={"row"}>
          <FormControl variant="standard">
            <Select
              size="small"
              value={value || 10}
              onChange={onChange}
              color={"secondary"}
            >
              {sizeOptions.map((size) => (
                <MenuItem value={size} key={size} color={"secondary"}>
                  {" "}
                  {size}{" "}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid2>
    </>
  );
};
