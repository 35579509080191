import Grid2 from "@mui/material/Unstable_Grid2";
import { DesktopAggregations } from "./devices/desktop-aggregations";
import { MobileAggregations } from "./devices/mobile-aggregations";
import { AggregationsProps } from "./types/AggregationsProps";

export const AggregationsList = ({ aggregations }: AggregationsProps) => {
  return (
    <>
      <Grid2 container>
        <Grid2 sx={{ display: { xs: "none", md: "inline" } }}>
          <DesktopAggregations aggregations={aggregations} />
        </Grid2>

        <Grid2 sx={{ display: { xs: "flex", md: "none" } }}>
          <MobileAggregations aggregations={aggregations} />
        </Grid2>
      </Grid2>
    </>
  );
};
