import { Field, Form } from "react-final-form";
import { Box, TextField } from "@mui/material";
import { useState } from "react";
import { PasswordVisibility } from "./password-visibility";
import { changePassword } from "../user.service";
import { useAlert } from "../../../utils/context/alert-context";
import { LoadingButton } from "../../../components/ui/buttons/loading-button";

export const ChangePassword = () => {
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const alert = useAlert();

  const submit = (data: { old_password: string; new_password: string }) => {
    changePassword(
      {
        previousPassword: data.old_password,
        proposedPassword: data.new_password,
      },
      setLoading,
      alert.handleAlert,
    ).subscribe();
  };

  const validate = (values: Record<string, any>) => {
    const errors: any = {};

    if (values.confirm_new_password !== values.new_password) {
      errors.confirm_new_password = "Passwords must match";
    }

    return errors;
  };

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Box display={"flex"} gap={2} flexDirection={"column"}>
            <Field name="old_password">
              {({ input }) => (
                <TextField
                  size="small"
                  color="secondary"
                  fullWidth
                  type={showOldPassword ? "text" : "password"}
                  label="Old Password"
                  required
                  {...input}
                  InputProps={{
                    endAdornment: (
                      <PasswordVisibility
                        visible={showOldPassword}
                        setVisible={setShowOldPassword}
                      />
                    ),
                  }}
                />
              )}
            </Field>
            <Field name={"new_password"}>
              {({ input }) => (
                <TextField
                  size="small"
                  color="secondary"
                  fullWidth
                  type={showNewPassword ? "text" : "password"}
                  label="New Password"
                  required
                  {...input}
                  InputProps={{
                    endAdornment: (
                      <PasswordVisibility
                        visible={showNewPassword}
                        setVisible={setShowNewPassword}
                      />
                    ),
                  }}
                />
              )}
            </Field>
            <Field name={"confirm_new_password"}>
              {({ input, meta }) => (
                <Box>
                  <TextField
                    size="small"
                    color="secondary"
                    fullWidth
                    type={showConfirmPassword ? "text" : "password"}
                    label="Confirm New Password"
                    required
                    error={meta.error && meta.touched}
                    helperText={meta.error}
                    {...input}
                    InputProps={{
                      endAdornment: (
                        <PasswordVisibility
                          visible={showConfirmPassword}
                          setVisible={setShowConfirmPassword}
                        />
                      ),
                    }}
                  />
                </Box>
              )}
            </Field>
            <Box display={"flex"} justifyContent={"right"}>
              <LoadingButton
                type={"submit"}
                disabled={submitting}
                title={"Change Password"}
                loading={loading}
              />
            </Box>
          </Box>
        </form>
      )}
    ></Form>
  );
};
