import { Alert, AlertColor, Box, Button, Snackbar } from "@mui/material";
import { useMemo, useState } from "react";

interface CopyLinkProps {
  basketId: string;
}

interface BasketAlert {
  open: boolean;
  message: string;
  severity: AlertColor;
}

export const CopyLink = ({ basketId }: CopyLinkProps) => {
  const [alert, setAlert] = useState<BasketAlert>();

  const link = useMemo(() => {
    if (basketId) {
      return `https://eltrp.org/favourites/${basketId}`;
    }
  }, [basketId]);

  const copyContent = (link?: string) => {
    if (basketId && link && navigator.clipboard) {
      navigator.clipboard
        .writeText(link)
        .then(() =>
          setAlert({
            open: true,
            message: "Copied content to clipboard",
            severity: "success",
          }),
        )
        .catch(() =>
          setAlert({
            open: true,
            message: `Error trying to copy content. Please copy manually.`,
            severity: "error",
          }),
        );
    } else if (basketId && link && !navigator.clipboard) {
      setAlert({
        open: true,
        message: `Error trying to copy content. Please copy manually.`,
        severity: "error",
      });
    }
  };
  return (
    <>
      <Box m={2}>
        {" "}
        Copy the following link and share it! The link is valid for a year.
        {basketId && (
          <Box
            display={"flex"}
            gap={1}
            justifyContent={"space-evenly"}
            overflow={"scroll"}
          >
            <a
              style={{ paddingLeft: 8 }}
              href={link}
              rel={"noreferrer"}
              target={"_blank"}
            >
              {link}
            </a>

            <Box sx={{ display: { xs: "none", md: "inline" } }}>
              <Button
                color={"secondary"}
                variant={"outlined"}
                size={"small"}
                onClick={() => copyContent(link)}
              >
                COPY LINK
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      {alert && (
        <Snackbar
          autoHideDuration={alert.severity === "success" ? 6000 : undefined}
          open={alert?.open}
          onClose={() => setAlert(undefined)}
        >
          <Alert severity={alert.severity}>{alert?.message}</Alert>
        </Snackbar>
      )}
    </>
  );
};
