export const PresentationFieldTwofold = (props: {
  metadata?: string[][];
  title: string;
}) => {
  const { metadata, title } = props;
  return metadata && metadata.length > 0 ? (
    <span>
      <b> {title}: </b>{" "}
      {metadata?.map((s) => s.filter(Boolean).join(", ")).join("; ")}
      <br />
    </span>
  ) : (
    <></>
  );
};
