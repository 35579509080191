import { EltrpPage } from "../layout/wrappers/eltrp-page";
import { EltrpHeader } from "../layout/wrappers/eltrp-header";
import { EltrpBody } from "../layout/wrappers/eltrp-body";
import { useEffect, useMemo, useState } from "react";
import { getBasket } from "../features/save/save-article.service";
import { SearchResponse } from "../models/SearchResult";
import { ResultPagination } from "../components/pagination/result-pagination";
import { Link, useSearchParams } from "react-router-dom";
import { useParamsStable } from "../hooks/use-params-stable";
import { AggregationsList } from "../components/aggregations/aggregations";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { EltrpLoading } from "../layout/wrappers/eltrp-loading";
import { EltrpSearchResults } from "../layout/wrappers/eltrp-search-results";
import { ResultBox } from "../features/result/result-box";
import { useAlert } from "../utils/context/alert-context";
import { useFavourites } from "../utils/context/favourites-context";
import { EltrpModal } from "../components/ui/modal/eltrp-modal";
import arrayMutators from "final-form-arrays";
import { Field, Form } from "react-final-form";

export const BasketPage = () => {
  const [response, setResponse] = useState<SearchResponse>();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const alert = useAlert();
  const favourites = useFavourites();

  const basketId = useParamsStable("basketId");

  const options = useMemo(() => {
    return favourites.getTagList();
  }, [favourites]);

  useEffect(() => {
    const from = searchParams.get("from") || "0";
    const size = searchParams.get("size") || "10";

    const filters = searchParams;
    const filtersAsArray: string[] = [];

    filters.forEach((value, key) =>
      key !== "from" && key !== "size"
        ? filtersAsArray.push([key, value].join(":"))
        : undefined,
    );

    if (basketId) {
      setLoading(true);
      getBasket(
        basketId,
        filtersAsArray,
        alert.handleAlert,
        setLoading,
        from,
        size,
      ).subscribe((res) => {
        setResponse(res);
        setLoading(false);
      });
    }
  }, [searchParams, basketId, alert]);

  const submit = (value: any) => {
    const ids = response?.hits.docs.map((doc) => doc._id);
    ids && favourites.addFavouritesChunk(ids, value.tag);
    window.location.reload();
  };

  const resultMessage = () => {
    const size = Number(searchParams.get("size"));
    const from = Number(searchParams.get("from"));
    const page =
      Number(searchParams.get("from")) /
        (Number(searchParams.get("size")) || 1) +
      1;

    return !response?.hits.total?.value || response?.hits.total.value === 0
      ? "No results"
      : "Showing " +
          (from + 1) +
          "-" +
          (response.hits?.total.value && size * page > response.hits.total.value
            ? response.hits?.total.value
            : size * (page | 1)) +
          " of " +
          response.hits?.total.value +
          " results";
  };

  return (
    <EltrpPage>
      <EltrpLoading loading={loading} />
      <EltrpHeader title={`Shared Articles`} />
      <EltrpBody>
        <EltrpSearchResults
          aggregations={
            <AggregationsList aggregations={response?.aggs || []} />
          }
          results={
            <Box mb={"2rem"}>
              <h2 style={{ marginBottom: 0 }}>Articles</h2>
              <Box display={"flex"} gap={1} flexDirection={"column"} mb={4}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  {resultMessage()}
                  <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={() => setOpen(true)}
                  >
                    Save shared articles
                  </Button>
                </Box>
                {basketId && (
                  <i>
                    {" "}
                    These articles have been shared with you with the id{" "}
                    {basketId}. View your own favourite articles{" "}
                    <Link to={"/favourites"}> here</Link>.
                  </i>
                )}
              </Box>
              <ResultBox results={response?.hits.docs} />
              <ResultPagination value={response?.hits.total.value} />{" "}
            </Box>
          }
          hits={response?.hits.total.value || 0}
        />
      </EltrpBody>
      <EltrpModal
        title={"Save shared article"}
        open={open}
        handleClose={() => setOpen(false)}
      >
        <Box>
          <Form
            onSubmit={submit}
            mutators={{ ...arrayMutators }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="tag"
                  component="input"
                  subscription={{ active: true, value: true }}
                >
                  {({ input, meta }) => (
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      m={2}
                      gap={1}
                      flexDirection={"column"}
                    >
                      <Autocomplete
                        freeSolo
                        style={{ width: "20rem" }}
                        size={"small"}
                        options={options}
                        renderInput={(params) => (
                          <TextField
                            color="secondary"
                            {...params}
                            size="small"
                            placeholder="Create or select a tag"
                            value={input.value}
                            onChange={(value) => input.onChange(value)}
                          />
                        )}
                        {...input}
                        onChange={(event, value, reason) =>
                          input.onChange(value)
                        }
                      />{" "}
                      <Button
                        type={"submit"}
                        color={"secondary"}
                        variant={"contained"}
                      >
                        {" "}
                        Save shared articles
                      </Button>
                    </Box>
                  )}
                </Field>
              </form>
            )}
          />
        </Box>
      </EltrpModal>
    </EltrpPage>
  );
};
