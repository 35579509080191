import { EltrpPage } from "../../layout/wrappers/eltrp-page";
import { EltrpHeader } from "../../layout/wrappers/eltrp-header";
import { EltrpBody } from "../../layout/wrappers/eltrp-body";
import { EltrpText } from "../../layout/wrappers/eltrp-text";

export const Contact = () => {
  return (
    <EltrpPage>
      <EltrpHeader title={"Contact us"} />
      <EltrpBody>
        <EltrpText>
          <h2> Suggest a missing report </h2>
          <p>
            {" "}
            Don't hesitate reaching out to us if you know of any articles that
            would be a good fit on the ELTRP site! Please email us at{" "}
            <a href={"mailto: info@eltrp.org"}>info@eltrp.org</a> with your
            suggestion.
          </p>
          <h2> Report a problem </h2>
          <p>
            {" "}
            For problems with an article, please use the{" "}
            <i> "Report a problem" </i> interaction at the article page and get
            a generated subject field. Email us at{" "}
            <a href={"mailto: info@eltrp.org"}>info@eltrp.org</a> with the
            generated subject and describe the problem with the article. <br />{" "}
            <br />
            For general problems with the site, email us at the address above
            describing the issue and we will try to get back as soon as
            possible.
          </p>
        </EltrpText>
      </EltrpBody>
    </EltrpPage>
  );
};
