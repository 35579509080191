import {IosShare} from "@mui/icons-material";
import {useMemo, useState} from "react";
import {updateBasket} from "../save/save-article.service";
import {Box, Button, Divider, MenuItem, OutlinedInput, Select, SelectChangeEvent, Typography,} from "@mui/material";
import {EltrpModal} from "../../components/ui/modal/eltrp-modal";
import {useAlert} from "../../utils/context/alert-context";
import {useFavourites} from "../../utils/context/favourites-context";
import {CopyLink} from "./copy-link"; // Gör en dialog om hur man vill dela, alla eller tagglsita


// Gör en dialog om hur man vill dela, alla eller tagglsita

export const ShareBasket = () => {
  const [basketId, setBasketId] = useState<string>();
  const [tagBasketId, setTagBasketId] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);
  //const [favouritesLink, setFavouritesLink] = useState<string>();
  //const [tagsLink, setTagsLink] = useState<string>();
  const [tag, setTag] = useState<string>();

  const alertContext = useAlert();
  const favourites = useFavourites();

  const tagCategories = useMemo(() => {
    return favourites.getTagList();
  }, [favourites]);

  const handleChange = (event: SelectChangeEvent<typeof tag>) => {
    const {
      target: { value },
    } = event;
    setTag(value);
  };

  const shareFavourites = () => {
    const ids = favourites.getArticleIds();
    ids &&
      updateBasket(ids, alertContext.handleAlert).subscribe((res) => {
        setBasketId(res);
        //setFavouritesLink(link(res));
      });
  };

  const shareTag = () => {
    if (tag) {
      const ids = favourites.getArticlesWithTag(tag);
      ids &&
        updateBasket(ids, alertContext.handleAlert).subscribe((res) => {
          setTagBasketId(res);
          //setTagsLink(link(res));
        });
    }
  };

  return (
    <Box mt={-1}>
      <Button
        startIcon={<IosShare />}
        onClick={() => setOpen(true)}
        variant={"contained"}
        color={"secondary"}
      >
        Share favourite articles
      </Button>

      <EltrpModal
        title={"Share Favourite Articles"}
        open={open}
        handleClose={() => {
          setOpen(false);
          setBasketId(undefined);
          setTagBasketId(undefined);
          setTag(undefined);
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={1}
          textAlign={"center"}
        >
          <span>
            Share articles with others by generating a link below. You can
            either share all your favorite articles or just articles with a
            certain tag.
          </span>
          <Divider>
            <Typography variant={"button"}>
              {" "}
              Share all favourite articles{" "}
            </Typography>
          </Divider>
          <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
            <Button
              color={"secondary"}
              variant={"contained"}
              onClick={shareFavourites}
            >
              {" "}
              Generate link
            </Button>
            {basketId && <CopyLink basketId={basketId} />}
          </Box>
          <Divider>
            {" "}
            <Typography variant={"button"}> Share tag lists </Typography>
          </Divider>
          <Box
            display={"flex"}
            alignItems={"center"}
            flexDirection={"column"}
            gap={1}
          >
            <Select
              displayEmpty
              value={tag || ""}
              onChange={handleChange}
              input={
                <OutlinedInput
                  size={"small"}
                  color={"secondary"}
                  sx={{ minWidth: "50%" }}
                />
              }
              renderValue={(selected) =>
                selected?.length === 0 ? (
                  <em>Select tag</em>
                ) : (
                  <em> {selected} </em>
                )
              }
            >
              {tagCategories.map((tag) => (
                <MenuItem key={tag} value={tag}>
                  {" "}
                  {tag}{" "}
                </MenuItem>
              ))}
            </Select>
            <Button
              color={"secondary"}
              variant={"contained"}
              onClick={shareTag}
              disabled={!tag}
            >
              Generate link
            </Button>
            {tagBasketId && <CopyLink basketId={tagBasketId} />}
          </Box>
        </Box>
      </EltrpModal>
    </Box>
  );
};
