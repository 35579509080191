import {
  Box,
  Chip,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useFavourites } from "../../../utils/context/favourites-context";
import { TagCategoriesForm } from "./tag-categories-form";
import { EltrpModal } from "../../../components/ui/modal/eltrp-modal";
import { ActionButton } from "../../../components/ui/buttons/action-button";

interface TagCategoriesProps {
  tagFilter: string[];
  setTagFilter: Dispatch<SetStateAction<string[]>>;
}

export const TagCategoriesAggregation = ({
  tagFilter,
  setTagFilter,
}: TagCategoriesProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const favourites = useFavourites();

  const tagCategories = useMemo(() => {
    return favourites.getTagList();
  }, [favourites]);

  const handleChange = (event: SelectChangeEvent<typeof tagFilter>) => {
    const {
      target: { value },
    } = event;
    setTagFilter(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <Box>
      <h2>{`Tag Categories ${tagFilter.length ? `(${tagFilter.length})` : ""}`}</h2>
      <i>Show articles with tags </i>

      <Box pt={1} pr={1} pb={1}>
        <Select
          multiple
          displayEmpty
          value={tagFilter}
          onChange={handleChange}
          input={<OutlinedInput size={"small"} fullWidth color={"secondary"} />}
          renderValue={(selected) =>
            selected.length === 0 ? (
              <em>Select tags</em>
            ) : (
              <Box display={"flex"} flexWrap={"wrap"} gap={0.5}>
                {selected.map((value) => (
                  <Chip key={value} label={value} size={"small"} />
                ))}
              </Box>
            )
          }
        >
          {tagCategories.map((tag) => (
            <MenuItem key={tag} value={tag}>
              {" "}
              {tag}{" "}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <ActionButton
        title={"Manage Tag Categories"}
        onClick={() => setOpen(true)}
      />

      <EltrpModal
        title={"Manage Tag Categories"}
        open={open}
        handleClose={() => setOpen(false)}
      >
        <TagCategoriesForm />
      </EltrpModal>
    </Box>
  );
};
