import Grid2 from "@mui/material/Unstable_Grid2";
import { Autocomplete, TextField } from "@mui/material";
import { Options } from "./options";

export const MultipleFieldsAutocomplete = (props: {
  input: any;
  label: string[];
  termId: string;
  leadId: string;
  active: any;
  placeholder?: string[];
}) => {
  const onChange = (index: number) => (value: any) => {
    const updated = [...props.input.value];
    updated[index] = value;
    props.input.onChange(updated);
  };

  return (
    <Grid2 container spacing={1}>
      <Grid2 xs={6}>
        <Autocomplete
          freeSolo
          options={Options(props.termId, props.active, props.input.value?.[0])}
          renderInput={(params) => (
            <TextField
              color="secondary"
              label={props.label[0]}
              {...params}
              size="small"
              InputLabelProps={{ shrink: true }}
              fullWidth
              required={!!props.input.value[1]}
              placeholder={props.placeholder?.[0]}
              key={props.input.name + "0"}
              onChange={(e) => onChange(0)(e.target.value)}
              margin="normal"
            />
          )}
          {...props.input}
          value={props.input.value[0] || ""}
          onChange={(event, value, reason) => onChange(0)(value)}
        />
      </Grid2>
      <Grid2 xs={6}>
        <Autocomplete
          freeSolo
          options={Options(
            props.leadId,
            props.active,
            props.input.value?.[1],
            props.input.value?.[0] || "",
          )}
          renderInput={(params) => (
            <TextField
              color="secondary"
              label={props.label[1]}
              {...params}
              size="small"
              InputLabelProps={{ shrink: true }}
              fullWidth
              placeholder={props.placeholder?.[1]}
              onChange={(e) => onChange(1)(e.target.value)}
              key={props.input.name + "1"}
              margin="normal"
            />
          )}
          {...props.input}
          value={props.input.value[1] || ""}
          onChange={(event, value, reason) => onChange(1)(value)}
        />
      </Grid2>
    </Grid2>
  );
};
