import { Field, Form } from "react-final-form";
import { Alert, Box, TextField } from "@mui/material";
import React, { useState } from "react";
import { forgotPassword } from "../../users/user.service";
import { StepperProps } from "../types/StepperProps";
import { useAlert } from "../../../utils/context/alert-context";
import { LoadingButton } from "../../../components/ui/buttons/loading-button";

export const ForgotPasswordForm = ({ step, setStep }: StepperProps) => {
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const alert = useAlert();

  const submit = (value: any) => {
    setLoading(true);
    forgotPassword(value.username, setLoading, alert.handleAlert).subscribe(
      (res) => {
        setLoading(false);
        setShowConfirmation(false);
        setStep((prevState) => prevState + 1);
      },
    );
  };
  return (
    <Box>
      {showConfirmation ? (
        <Alert> An email with instructions has been sent. </Alert>
      ) : (
        <Form
          onSubmit={submit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Field name={"username"}>
                  {({ input }) => (
                    <TextField
                      size={"small"}
                      fullWidth
                      label={"Email"}
                      color={"secondary"}
                      required
                      {...input}
                    />
                  )}
                </Field>
                <LoadingButton
                  type={"submit"}
                  title={"Send recovery code"}
                  loading={loading}
                />
              </Box>
            </form>
          )}
        ></Form>
      )}
    </Box>
  );
};
