import Grid2 from "@mui/material/Unstable_Grid2";
import { Autocomplete, TextField } from "@mui/material";
import { Options } from "./options";

export const LinkField = (props: {
  input: any;
  active: any;
  label: string[];
  termId: string[];
  index: any;
  placeholder?: string[];
}) => {
  const onChange = (index: number) => (value: any) => {
    const updated = [...props.input.value];
    updated[index] = value;
    props.input.onChange(updated);
  };

  return (
    <Grid2 container spacing={1}>
      <Grid2 xs={4}>
        <Autocomplete
          freeSolo
          options={Options(
            props.termId[0],
            props.active,
            props.input.value?.[0],
          )}
          renderInput={(params) => (
            <TextField
              color="secondary"
              label={props.label[0]}
              {...params}
              size="small"
              InputLabelProps={{ shrink: true }}
              fullWidth
              required={props.input.value[1] || props.input.value[2]}
              placeholder={props.placeholder?.[0]}
              onChange={(e) => {
                return onChange(0)(e.target.value);
              }}
              margin="normal"
            />
          )}
          {...props.input}
          value={props.input.value[0] || ""}
          onChange={(event, value, reason) => {
            return onChange(0)(value);
          }}
        />
      </Grid2>
      <Grid2 xs={4}>
        <TextField
          label={props.label[1]}
          color="secondary"
          {...props.input}
          size="small"
          InputLabelProps={{ shrink: true }}
          fullWidth
          required={props.input.value[0] || props.input.value[2]}
          placeholder={props.placeholder?.[1]}
          value={props.input.value[1] || ""}
          onChange={(e) => onChange(1)(e.target.value)}
          margin="normal"
        />
      </Grid2>
      <Grid2 xs={4}>
        <Autocomplete
          freeSolo
          options={Options(
            props.termId[1],
            props.active,
            props.input.value?.[2],
          )}
          renderInput={(params) => (
            <TextField
              color="secondary"
              label={props.label[2]}
              {...params}
              onChange={(e) => {
                return onChange(2)(e.target.value);
              }}
              size="small"
              placeholder={props.placeholder?.[2]}
              InputLabelProps={{ shrink: true }}
              fullWidth
              margin="normal"
            />
          )}
          {...props.input}
          value={props.input.value[2] || ""}
          onChange={(event, value, reason) => onChange(2)(value)}
        />
      </Grid2>
    </Grid2>
  );
};