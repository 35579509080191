import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SearchPage } from "./pages/search-page";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ArticlePage } from "./pages/article-page";
import { ArticleEditPage } from "./features/edit/article-edit-page";
import { AdminBar } from "./components/admin/admin-bar";
import { LandingPage } from "./pages/landing-page";
import { DictionaryPage } from "./pages/dictionary-page";
import { ErrorBar } from "./components/error/error-bar";
import { Copyright } from "./pages/link-pages/copyright";
import { DataPolicy } from "./pages/link-pages/data-policy";
import { HowToSearch } from "./pages/link-pages/how-to-search";
import { About } from "./pages/link-pages/about";
import { theme } from "./utils/theme/theme";
import { PrintablePage } from "./features/article/print/printable-page";
import { NewsletterPage } from "./pages/newsletter-page";
import { Contact } from "./pages/link-pages/contact";
import { BasketPage } from "./pages/basket-page";
import { FavoritesPage } from "./pages/favorites-page";
import { AuthProvider } from "./utils/context/auth-context";
import { EnvProvider } from "./utils/context/env-context";
import { AlertProvider } from "./utils/context/alert-context";
import { UserSettingsPage } from "./features/users/components/user-settings-page";
import { ResetPasswordPage } from "./features/auth/pages/reset-password-page";
import { SignUpPage } from "./features/auth/pages/sign-up-page";
import { SignInPage } from "./features/auth/pages/sign-in-page";
import { FavouritesProvider } from "./utils/context/favourites-context";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <AuthProvider>
          <EnvProvider>
            <AlertProvider>
              <FavouritesProvider>
                <AdminBar />
                <ErrorBar />
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route path={"/search"} element={<SearchPage />} />
                  <Route path={"/article/:id"} element={<ArticlePage />} />
                  <Route
                    path={"/article/:id/edit"}
                    element={<ArticleEditPage />}
                  />
                  <Route path="/dictionary" element={<DictionaryPage />} />
                  <Route path="/copyright" element={<Copyright />} />
                  <Route path="/data-policy" element={<DataPolicy />} />
                  <Route path="/how-to-search" element={<HowToSearch />} />
                  <Route path="/about" element={<About />} />
                  <Route
                    path={"/article/:id/print"}
                    element={<PrintablePage />}
                  />
                  <Route path={"/newsletter"} element={<NewsletterPage />} />
                  <Route path={"/contact"} element={<Contact />} />
                  <Route path={"/favourites"} element={<FavoritesPage />} />
                  <Route
                    path={"/favourites/:basketId"}
                    element={<BasketPage />}
                  />
                  <Route
                    path={"/user-settings"}
                    element={<UserSettingsPage />}
                  />
                  <Route path={"/reset"} element={<ResetPasswordPage />} />
                  <Route path={"/sign-up"} element={<SignUpPage />} />
                  <Route path={"/sign-in"} element={<SignInPage />} />
                </Routes>
              </FavouritesProvider>
            </AlertProvider>
          </EnvProvider>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
);
