import { Alert, Snackbar } from "@mui/material";
import { useAlert } from "../../utils/context/alert-context";

export const ErrorBar = () => {
  const alert = useAlert();

  return (
    <Snackbar
      open={alert.alert.open}
      autoHideDuration={alert.alert.persistent ? null : 6000}
      onClose={() =>
        alert.setAlert({
          message: "",
          extra: null,
          statusText: "",
          open: false,
        })
      }
    >
      <Alert
        severity="error"
        sx={{ width: "100%" }}
        onClose={() =>
          alert.setAlert({
            message: "",
            extra: null,
            statusText: "",
            open: false,
          })
        }
      >
        <b> {alert.alert.message} </b> <br />
        <>
          {" "}
          {alert.alert.statusText} <br />
          {alert.alert.extra
            ? JSON.stringify(alert.alert.extra, null, 2)
            : null}{" "}
        </>
      </Alert>
    </Snackbar>
  );
};
