import Grid2 from "@mui/material/Unstable_Grid2";
import { theme } from "../../utils/theme/theme";
import { Box, Typography } from "@mui/material";
import ELTRP_Logo_Vertical_black from "../../assets/logos/ELTRP_Logo_Vertical_black.png";
import { Fragment, PropsWithChildren } from "react";
import { Link } from "react-router-dom";

export const EltrpAuthPage = ({ children }: PropsWithChildren) => {
  return (
    <Fragment>
      <Box
        flexDirection={"column"}
        display={{ xs: "flex", md: "none" }}
        height={"100vh"}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          textAlign={"center"}
          flexGrow={1}
        >
          {children}
        </Box>
        <Box
          mt={"auto"}
          sx={{
            backgroundColor: theme.palette.primary.light,
            boxShadow: "-10px 0px 20px 1px rgba(99, 99, 99, 0.2)",
          }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={2}
            p={2}
          >
            <Box>
              <Link to={"/"}>
                <img
                  alt={"ELTRP LOGO"}
                  src={ELTRP_Logo_Vertical_black}
                  style={{ width: "min(300px,35vw)" }}
                />
              </Link>
            </Box>
            <Box textAlign={"center"} display={"flex"} flexDirection={"column"}>
              <span>
                Having trouble signing in or creating an account? Contact us at{" "}
                <a href={"mailto:info@eltrp.org"}> info@eltrp.org</a>.
              </span>
              <br />
              <Typography variant={"button"}>
                <Link to={"/"}>Back to ELTRP </Link>{" "}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Grid2
        container
        width={"100vw"}
        height={"100vh"}
        display={{ xs: "none", md: "flex" }}
      >
        <Grid2 md={8}>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            textAlign={"center"}
            height={"100vh"}
          >
            {children}
          </Box>
        </Grid2>
        <Grid2
          md={4}
          sx={{
            backgroundColor: theme.palette.primary.light,
            boxShadow: "-10px 0px 20px 1px rgba(99, 99, 99, 0.2)",
          }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"space-between"}
            p={2}
            height={"100vh"}
          >
            <Box>
              <Link to={"/"}>
                <img
                  alt={"ELTRP LOGO"}
                  src={ELTRP_Logo_Vertical_black}
                  style={{ width: "min(300px,35vw)" }}
                />
              </Link>
            </Box>
            <Box textAlign={"center"} display={"flex"} flexDirection={"column"}>
              <span>
                Having trouble signing in or creating an account? Contact us at{" "}
                <a href={"mailto:info@eltrp.org"}> info@eltrp.org</a>.
              </span>
              <br />
              <Typography variant={"button"}>
                <Link to={"/"}>Back to ELTRP </Link>{" "}
              </Typography>
            </Box>
          </Box>
        </Grid2>
      </Grid2>
    </Fragment>
  );
};
