import { PropsWithChildren } from "react";

interface IPresentationField extends PropsWithChildren {
  metadata?: string;
  title: string;
}

export const PresentationField = (props: IPresentationField) => {
  const { metadata, title, children } = props;
  return children || metadata ? (
    <span style={{ display: "flex", flexWrap: "wrap" }}>
      <b> {title}:&nbsp; </b> {children || metadata} <br />
    </span>
  ) : (
    <></>
  );
};
