import { Button, ButtonProps, styled, Typography } from "@mui/material";

interface IActionButtonProps extends ButtonProps {
  title: string;
}

const StyledActionButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  padding: 0,
  "&:hover": {
    textDecoration: "underline",
  },
}));

export const ActionButton = (props: IActionButtonProps) => {
  const { title, ...rest } = props;
  return (
    <StyledActionButton disableRipple={true} {...rest}>
      <Typography variant={"button"}>{title}</Typography>
    </StyledActionButton>
  );
};