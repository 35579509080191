import { EltrpAuthPage } from "../../../layout/wrappers/eltrp-auth-page";
import { Alert, Box, Button } from "@mui/material";
import { SignUpForm } from "../components/sign-up-form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmSignUpForm } from "../components/confirm-sign-up-form";
import { EltrpStepper } from "../../../components/ui/stepper/eltrp-stepper";
import { FormWrapper } from "../components/form-wrapper";

export const SignUpPage = () => {
  const [step, setStep] = useState<number>(0);

  const navigate = useNavigate();
  return (
    <EltrpAuthPage>
      <FormWrapper title={"Sign up"} subtitle={"Create an account at ELTRP!"}>
        <EltrpStepper
          activeStep={step}
          setActiveStep={setStep}
          steps={[
            {
              label: "Create account",
              component: <SignUpForm step={step} setStep={setStep} />,
            },
            {
              label: "Confirm account",
              component: <ConfirmSignUpForm step={step} setStep={setStep} />,
            },
          ]}
          finish={
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <Alert> Account has been confirmed </Alert>
              <Button
                color={"secondary"}
                variant={"contained"}
                onClick={() => navigate("/sign-in")}
              >
                Sign in to account
              </Button>
            </Box>
          }
        />
      </FormWrapper>
    </EltrpAuthPage>
  );
};
