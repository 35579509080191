import { httpClient } from "../../utils/http/httpClient";
import { API } from "../../environment/api";
import { catchError, map, throwError } from "rxjs";
import { Article } from "../../models/Article";
import { IAutocomplete } from "../../models/Autocomplete";
import { ErrorMap } from "../../models/ErrorMap";

export function getArticle(
  id: string,
  setError: (err: ErrorMap) => void,
  setLoading: (val: boolean) => void,
) {
  return httpClient
    .get(API.article.get, { pathParams: { id }, authenticated: true })
    .pipe(
      map((res) => res.data as Article),
      catchError((err) => {
        setError({ statusCode: err.response.status, message: err.message });
        setLoading(false);
        return throwError(err);
      }),
    );
}

export function updateArticle(
  id: string,
  article: Article,
  setError: (err: ErrorMap) => void,
  setLoading: (val: boolean) => void,
) {
  return httpClient
    .put(API.article.update, article.metadata, {
      pathParams: { id },
      authenticated: true,
    })
    .pipe(
      map((res) => res.data as Article),
      catchError((err) => {
        setError({
          statusCode: err.response.status,
          message: err.response.data.message,
          extra: err.response.data.extra,
        });
        setLoading(false);
        return throwError(err);
      }),
    );
}

export function getAutocomplete(termId: string, query: string, lead?: string) {
  return httpClient
    .get(API.autocomplete, {
      pathParams: { termId },
      queryParams: { lead, query: query || "" },
      authenticated: true,
    })
    .pipe(map((res) => res.data as IAutocomplete));
}

export function getArticleWithAttachment(
  id: string,
  setError: (err: ErrorMap) => void,
  setLoading: (val: boolean) => void,
) {
  return httpClient
    .get(API.article.attachment.get, {
      pathParams: { id },
    })
    .pipe(
      map((res) => res.data as Article),
      catchError((err) => {
        setError({ statusCode: err.response.status, message: err.message });
        setLoading(false);
        return throwError(err);
      }),
    );
}
