import { Options } from "../../edit/fields/options";
import { Autocomplete, InputProps, TextField } from "@mui/material";

interface IGlobalChangeAutocompleteProps extends InputProps {
  termId: string;
  active?: boolean;
  input: any;
}

export const GlobalChangeAutocomplete = (
  props: IGlobalChangeAutocompleteProps,
) => {
  return (
    <Autocomplete
      options={Options(props.termId, props.active, props.input.value)}
      renderInput={(params) => (
        <TextField
          color="secondary"
          variant="outlined"
          required
          {...params}
          size="small"
          value={props.input.value}
          onChange={props.input.onChange}
        />
      )}
      {...props.input}
      value={props.input.value || ""}
      onChange={(event, value, reason) => props.input.onChange(value)}
    />
  );
};
