import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Box, IconButton, TextField } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Search } from "@mui/icons-material";

export const SearchBoxLanding = () => {
  const [searchParams] = useSearchParams();
  const [query, setQuery] = useState<string>("");

  const navigate = useNavigate();

  useEffect(() => {
    setQuery(searchParams.get("query") || "");
  }, [searchParams]);

  return (
    <Grid2 xs={12} display="flex" justifyContent={"center"}>
      <Box
        sx={{
          width: "90%",
          minWidth: { xs: "300px", sm: "500px" },
          maxWidth: "600px",
          padding: "2.5% 0 2.5% 0",
          margin: { xs: "1.5rem 0 1.5rem 0", sm: "2.5rem 0 2.5rem 0" },
        }}
        className="searchBox"
      >
        <h3 style={{ margin: 0 }}> Find research at ELTRP </h3>
        <form>
          <TextField
            style={{ fontSize: "2rem" }}
            focused
            variant="outlined"
            size="medium"
            fullWidth
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            color="primary"
            InputProps={{
              endAdornment: (
                <IconButton
                  size="large"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    return navigate(
                      "/search/?query=" + query + "&from=0&size=10",
                    );
                  }}
                >
                  <Search fontSize="large" />
                </IconButton>
              ),
            }}
            sx={{
              input: { fontSize: 20 },
              fontSize: "3rem",
              boxShadow: "0px 2px 8px 0px rgba(99, 99, 99, 0.3)",
            }}
          />
        </form>
      </Box>
    </Grid2>
  );
};