import { Article } from "../../../models/Article";
import ELTRP_Logo_Horizontal_for_white_background from "../../../assets/logos/ELTRP_Logo_Horizontal_for_white_background.png";
import { Alert, Divider } from "@mui/material";
import { PresentationField } from "../components/fields/presentation-field";
import { PresentationFieldList } from "../components/fields/presentation-field-list";
import { PresentationFieldTwofold } from "../components/fields/presentation-field-twofold";
import { useParamsStable } from "../../../hooks/use-params-stable";
import { useEffect, useState } from "react";
import { getArticle } from "../article.service";
import { EltrpLoading } from "../../../layout/wrappers/eltrp-loading";
import { useAlert } from "../../../utils/context/alert-context";

export const PrintablePage = () => {
  const id = useParamsStable("id");
  const [article, setArticle] = useState<Article>();
  const [loading, setLoading] = useState(false);

  const [openPrint, setOpenPrint] = useState(false);

  const alert = useAlert();

  useEffect(() => {
    setLoading(true);
    id &&
      getArticle(id, alert.handleAlert, setLoading).subscribe((a) => {
        setLoading(false);
        setArticle(a);
        setOpenPrint(true);
      });
  }, [id, alert]);

  useEffect(() => {
    openPrint && article?.metadata && window.print();
    window.onafterprint = () => {
      setOpenPrint(false);
      window.history.back();
    };
  }, [article, openPrint]);

  return (
    <div>
      <div className={"no-print"}>
        <Alert severity={"info"}>
          If the print dialog do not open automatically, please use the print
          function on your computer or mobile device.
        </Alert>
        <EltrpLoading loading={loading} />
      </div>
      <div style={{ padding: " 0.5rem 0rem 0.5rem 2rem" }}>
        <img
          alt={"ELTRP LOGO"}
          src={ELTRP_Logo_Horizontal_for_white_background}
          style={{ width: "400px" }}
        />
      </div>
      <div
        style={{
          padding: "2rem",
          border: "solid",
          borderWidth: "1px",
        }}
      >
        <span> Comment by ELTRP: {article?.metadata.commentExt}</span>
        <h1>{article?.metadata.titleEn}</h1>
        <span>
          <ul style={{ listStyle: "none", padding: "0%" }}>
            <li>
              {article?.metadata?.author &&
              article.metadata.author.length > 0 ? (
                article?.metadata.author?.join("; ")
              ) : (
                <></>
              )}
            </li>
            <li>{article?.metadata?.type ? article?.metadata.type : <></>}</li>
            <li>{article?.metadata?.year ? article?.metadata.year : <></>}</li>
          </ul>
        </span>
        {article?.metadata?.link && article.metadata.link.length > 0 ? (
          <span>
            <h3> Access </h3>
            <ul>
              {article?.metadata.link.map((url) => (
                <li key={url[1]}>
                  <span>
                    {url[0] + " "}
                    <a
                      href={url[1] ? url[1] : undefined}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {url[1]}
                    </a>
                    {url[2] ? "(" + url[2] + ")" : ""}
                  </span>
                </li>
              ))}
            </ul>
          </span>
        ) : (
          <></>
        )}
        <div style={{ textAlign: "right" }}>
          {article?.metadata?.titleTranslated ? (
            <small> Title translated by ELTRP </small>
          ) : (
            ""
          )}
        </div>
      </div>
      <div style={{ padding: "2rem" }}>
        <div>
          <span>
            {article?.metadata?.abstractEn ? (
              <>
                <h3> Abstract [en] </h3>
                <p> {article?.metadata.abstractEn} </p>
              </>
            ) : (
              <></>
            )}
          </span>
          <div style={{ textAlign: "right" }}>
            {article?.metadata?.abstractTranslated ? (
              <small> Abstract translated by ELTRP </small>
            ) : (
              ""
            )}
          </div>
        </div>
        <div>
          <span>
            {article?.metadata?.abstractOther ? (
              <>
                <h3> Abstract [other] </h3>
                <p> {article?.metadata.abstractOther} </p>
              </>
            ) : (
              <></>
            )}
          </span>
        </div>
        <div>
          <span>
            {article?.metadata?.summary ? (
              <>
                <h3> Summary </h3>
                <p> {article?.metadata.summary} </p>
              </>
            ) : (
              ""
            )}
          </span>
        </div>
        {article?.metadata?.subject && article.metadata.subject.length > 0 ? (
          <Divider>
            <h4> KEYWORDS </h4>
          </Divider>
        ) : (
          <></>
        )}
        <div>{article?.metadata.subject?.join(", ")}</div>
        {article?.metadata ? (
          <Divider>
            <h4> ARTICLE INFORMATION </h4>
          </Divider>
        ) : (
          <></>
        )}
        <div>
          <PresentationField
            title={"Title other"}
            metadata={article?.metadata?.titleOther}
          />
          <PresentationFieldList
            title={"Author"}
            metadata={article?.metadata?.author}
          />
          <PresentationFieldTwofold
            title={"Organisation"}
            metadata={article?.metadata?.organization}
          />
          <PresentationField
            title={"Original language"}
            metadata={article?.metadata?.language}
          />
          <PresentationFieldList
            title={"Country"}
            metadata={article?.metadata?.country}
          />
          <PresentationFieldTwofold
            title={"Periodical"}
            metadata={article?.metadata?.periodical}
          />
          <PresentationFieldTwofold
            title={"Series"}
            metadata={article?.metadata?.series}
          />
          <PresentationFieldTwofold
            title={"Conference"}
            metadata={article?.metadata?.conference}
          />
          <PresentationField
            title={"Year"}
            metadata={article?.metadata?.year}
          />
          {article?.metadata?.contributor &&
          article.metadata.contributor.length > 0 ? (
            <span>
              <b> Contributor: </b>
              {article?.metadata.contributor
                ?.map((c) => c[0] + (c[1] !== null ? " (" + c[1] + ")" : ""))
                .join("; ")}
              <br />
            </span>
          ) : (
            <></>
          )}
          <PresentationFieldTwofold
            title={"Identifier"}
            metadata={article?.metadata?.identifier}
          />
          <PresentationFieldTwofold
            title={"Related articles"}
            metadata={article?.metadata?.relation}
          />
        </div>
      </div>
      <Divider />
      <div style={{ textAlign: "right" }}>
        <small>
          This is a print specific rendering of an ELTRP article. <br />
          For an interactive experience, please visit our website at{" "}
          <a href={"https://eltrp.org/"}>eltrp.org</a>.
        </small>
      </div>
    </div>
  );
};
