type mapping = {
  [key: string]: { name: string; importance: number; open?: boolean };
};
export const fieldNameMapping: mapping = {
  LINK_access: { name: "Access type", importance: 1, open: true },
  TYPE: { name: "Type", importance: 3, open: true },
  LANGUAGE: { name: "Language", importance: 7 },
  YEAR: { name: "Year", importance: 4 },
  IDENTIFIER_type: { name: "Identifier", importance: 11 },
  COUNTRY: { name: "Country", importance: 6 },
  SUBJECT: { name: "Keyword", importance: 8 },
  ORGANIZATION_lead: { name: "Organisation", importance: 9 },
  CONTRIBUTOR_lead: { name: "Contributor", importance: 10 },
  AUTHOR: { name: "Author", importance: 5 },
  PERIODICAL_lead: { name: "Periodical", importance: 12 },
  SERIES_lead: { name: "Series", importance: 13 },
  CONFERENCE_lead: { name: "Conference", importance: 14 },
  ATTACHMENT_TYPE: { name: "Attachment", importance: -2, open: false },
  PUBLISHED: { name: "Published", importance: -1, open: false },
  CONTRIBUTOR_type: { name: "Contributor Type", importance: 17 },
  LINK_service: { name: "Service", importance: 2, open: true },
};
