import { Chip } from "@mui/material";
import { Link } from "react-router-dom";

export const DictionaryChips = (props: {
  subjects?: string[];
  dictionaryJSON: any;
}) => {
  const { subjects, dictionaryJSON } = props;
  return (
    <>
      {" "}
      {subjects?.map((s: string) => (
        <Chip
          key={s}
          label={
            Object.keys(dictionaryJSON)
              .map((m) => m)
              .includes(s) ? (
              <Link to="/dictionary" state={{ subject: s.toString() }}>
                {" "}
                <b> {s} </b>{" "}
              </Link>
            ) : (
              s
            )
          }
          style={{ margin: "4px 4px 4px 0" }}
          clickable={Object.keys(dictionaryJSON)
            .map((m) => m)
            .includes(s)}
        />
      ))}
    </>
  );
};
