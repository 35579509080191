import { useEffect, useState } from "react";
import { SearchResponse } from "../models/SearchResult";
import { Link, useSearchParams } from "react-router-dom";
import { getSavedArticles } from "../features/save/save-article.service";
import { EltrpPage } from "../layout/wrappers/eltrp-page";
import { EltrpHeader } from "../layout/wrappers/eltrp-header";
import { EltrpBody } from "../layout/wrappers/eltrp-body";
import Grid2 from "@mui/material/Unstable_Grid2";
import { EltrpText } from "../layout/wrappers/eltrp-text";
import { Box, Divider, Typography } from "@mui/material";
import { AggregationsList } from "../components/aggregations/aggregations";
import { ShareBasket } from "../features/share/share-basket";
import { ResultPagination } from "../components/pagination/result-pagination";
import { EltrpLoading } from "../layout/wrappers/eltrp-loading";
import { ResultBox } from "../features/result/result-box";
import { EltrpSearchResults } from "../layout/wrappers/eltrp-search-results";

import { MobileAggregations } from "../components/aggregations/devices/mobile-aggregations";
import { useAlert } from "../utils/context/alert-context";
import { useFavourites } from "../utils/context/favourites-context";
import { TagCategoriesAggregation } from "../features/save/components/tag-categories-aggregation";

export const FavoritesPage = () => {
  const [response, setResponse] = useState<SearchResponse>();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [tagFilter, setTagFilter] = useState<string[]>([]);

  const alert = useAlert();
  const favourites = useFavourites();

  const includesAny = (arr?: string[], values?: string[]) =>
    values?.some((v) => arr?.includes(v));

  useEffect(() => {
    const from = searchParams.get("from") || "0";
    const size = searchParams.get("size") || "10";

    const ids = favourites.getArticleIds();

    // Filter on ids that also match the tag filter

    const newIds = !!tagFilter.length
      ? ids.filter((id) =>
          includesAny(favourites.getTagsFromArticle(id), tagFilter),
        )
      : ids;

    const filters = searchParams;
    const filtersAsArray: string[] = [];

    filters.forEach((value, key) =>
      key !== "from" && key !== "baskedId" && key !== "size"
        ? filtersAsArray.push([key, value].join(":"))
        : undefined,
    );

    if (newIds) {
      setLoading(true);
      getSavedArticles(
        newIds,
        filtersAsArray,
        alert.handleAlert,
        setLoading,
        from,
        size,
      ).subscribe((res) => {
        setResponse(res);
        setLoading(false);
      });
    }
  }, [searchParams, alert, tagFilter, favourites]);

  const resultMessage = () => {
    const size = Number(searchParams.get("size"));
    const from = Number(searchParams.get("from"));
    const page =
      Number(searchParams.get("from")) /
        (Number(searchParams.get("size")) || 1) +
      1;

    return !response
      ? ""
      : !response?.hits.total?.value || response?.hits.total.value === 0
        ? "You have no saved articles, try saving some!"
        : "Showing " +
          (from + 1) +
          "-" +
          (response.hits?.total.value && size * page > response.hits.total.value
            ? response.hits?.total.value
            : size * (page | 1)) +
          " of " +
          response.hits?.total.value +
          " results";
  };

  return (
    <EltrpPage>
      <EltrpLoading loading={loading} />
      <EltrpHeader title={"Favourite Articles"} />
      <EltrpBody>
        <Grid2>
          <EltrpText>
            <h2> All your favourite articles in one place</h2>
            <p>
              {" "}
              Save articles for future use or to share with friends and
              colleagues!{" "}
            </p>
            <h3> How to use it </h3>
            <p>
              Next to each article, there is a save icon. This enable you to
              save up to 100 articles. You can also share your favourite
              articles by generating a link below. If you update your list of
              saved articles you need to generate a new link if you want to
              share your updated favourites. The articles are saved using local
              storage connected to your current browser. Read more about our use
              of local storage under{" "}
              <Link to={"/data-policy"}> ELTRP data protection policy</Link>.
            </p>
          </EltrpText>
          <Divider />
        </Grid2>
        <EltrpSearchResults
          hits={response?.hits.total.value || 0}
          aggregations={
            <Box pr={1}>
              <TagCategoriesAggregation
                tagFilter={tagFilter}
                setTagFilter={setTagFilter}
              />
              <AggregationsList aggregations={response?.aggs || []} />
            </Box>
          }
          results={
            <Box mb={"2rem"}>
              <Grid2
                container
                sx={{ display: { xs: "none", md: "flex" } }}
                mb={4}
              >
                <Grid2 xs={12}>
                  <h2> Saved Articles </h2>
                </Grid2>
                <Grid2
                  xs={12}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  {resultMessage()}
                  {!!response?.hits.total.value && <ShareBasket />}
                </Grid2>
              </Grid2>

              <Grid2 container display={{ xs: "flex", md: "none" }} mb={4}>
                <Grid2 xs={8}>
                  <h2 style={{ marginBottom: "0" }}> Saved Articles </h2>
                  <Typography variant={"subtitle2"}>
                    {resultMessage()}
                  </Typography>
                </Grid2>
                <Grid2
                  display={"flex"}
                  xs={4}
                  justifyContent={"right"}
                  alignItems={"center"}
                >
                  {response?.hits.total.value !== 0 && (
                    <MobileAggregations aggregations={response?.aggs || []} />
                  )}
                </Grid2>
                <Grid2 xs={12} mt={2}>
                  {!!response?.hits.total.value && <ShareBasket />}
                </Grid2>
              </Grid2>

              <ResultBox results={response?.hits.docs} />
              <ResultPagination value={response?.hits.total.value} />
            </Box>
          }
        />
      </EltrpBody>
    </EltrpPage>
  );
};