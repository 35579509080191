import { Field } from "react-final-form";
import { Autocomplete, TextField } from "@mui/material";
import { Options } from "./options";

export const SingleFieldsAutocomplete = (props: {
  fieldName: string;
  termId: string;
  label: string;
  placeholder?: string;
}) => {
  return (
    <Field name={props.fieldName} subscription={{ active: true, value: true }}>
      {({ input, meta }) => (
        <Autocomplete
          freeSolo
          options={Options(props.termId, meta.active, input.value)}
          renderInput={(params) => (
            <TextField
              color="secondary"
              margin="normal"
              variant="outlined"
              label={props.label}
              required
              {...params}
              InputLabelProps={{ shrink: true }}
              size="small"
              placeholder={props.placeholder}
              onChange={(e) => input.onChange(e.target.value)}
            />
          )}
          {...input}
          value={input.value || ""}
          onChange={(event, value, reason) => input.onChange(value)}
        />
      )}
    </Field>
  );
};
