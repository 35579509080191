import { ActionButton } from "../../components/ui/buttons/action-button";
import { useMemo, useState } from "react";
import { generatePath } from "react-router-dom";
import { Alert, Box, Snackbar } from "@mui/material";
import { Share } from "@mui/icons-material";

import { ShareAlert } from "../../models/ShareAlert";

export const ShareArticle = (props: { articleId?: string }) => {
  const { articleId } = props;
  const [alert, setAlert] = useState<ShareAlert>();

  const path = useMemo(() => {
    const baseURL = "https://eltrp.org";
    return (
      articleId && baseURL + generatePath("/article/:id", { id: articleId })
    );
  }, [articleId]);
  const shareResult = () => {
    if (path && navigator.clipboard) {
      navigator.clipboard
        .writeText(path)
        .then(() =>
          setAlert({
            open: true,
            message:
              "Copied link to article to clipboard. Share the link with others!",
            severity: "success",
          }),
        )
        .catch(() =>
          setAlert({
            open: true,
            message: `Error trying to copy content. Please navigate to the article and copy page url manually.`,
            severity: "error",
          }),
        );
    } else if (!navigator.clipboard) {
      setAlert({
        open: true,
        message: `Error trying to copy content. Please navigate to the article and copy page url manually.`,
        severity: "error",
      });
    }
  };

  return (
    <Box display={{ xs: "none", md: "block" }}>
      <ActionButton
        title={"Share Article"}
        onClick={shareResult}
        startIcon={<Share />}
      />
      {alert && (
        <Snackbar
          autoHideDuration={alert.severity === "success" ? 6000 : undefined}
          open={alert?.open}
          onClose={() => setAlert(undefined)}
        >
          <Alert severity={alert.severity}>{alert?.message}</Alert>
        </Snackbar>
      )}
    </Box>
  );
};