import {
  Add,
  ChangeCircle,
  Edit,
  MailOutline,
  Verified,
} from "@mui/icons-material";
import { AppBar, Box, Button, Divider, Toolbar } from "@mui/material";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PdfUploadBox } from "../../features/pdf/components/pdf-upload";
import { QualityControlBox } from "../../features/quality-control/components/quality-control-box";
import { NewsletterBox } from "../../features/newsletter/components/newsletter-box";
import { GlobalChangeBox } from "../../features/global-change/components/global-change-box";
import { useAuth } from "../../utils/context/auth-context";
import { useEnv } from "../../utils/context/env-context";

export const AdminBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState<boolean>(false);
  const [openQC, setOpenQC] = useState(false);
  const [openGC, setOpenGC] = useState(false);

  const [openNL, setOpenNL] = useState(false);

  const auth = useAuth();

  const env = useEnv();

  const loggedIn = useMemo(() => auth.authenticated, [auth]);

  return (
    <Box display={env.env === "ADMIN" && auth.authenticated ? "box" : "none"}>
      <AppBar
        className="adminBar"
        color="secondary"
        position="static"
        style={{ padding: "0%", backgroundColor: "white", boxShadow: "none" }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box
            display={
              loggedIn && !location.pathname.includes("edit") ? "flex" : "none"
            }
            flexGrow={1}
            gap={2}
          >
            <Button
              startIcon={<Add />}
              color="secondary"
              size={"small"}
              onClick={() => setOpen(true)}
            >
              Create New Article
            </Button>
            <Box
              display={
                location.pathname.includes("article") &&
                !location.pathname.includes("edit")
                  ? "flex"
                  : "none"
              }
            >
              <Button
                startIcon={<Edit />}
                color="secondary"
                onClick={() => navigate(location.pathname + "/edit")}
                size="small"
              >
                Edit article
              </Button>
            </Box>
            <Button
              startIcon={<Verified />}
              color={"secondary"}
              size={"small"}
              onClick={() => setOpenQC(true)}
            >
              Quality Control
            </Button>
            <Button
              startIcon={<ChangeCircle />}
              color={"secondary"}
              size={"small"}
              onClick={() => setOpenGC(true)}
            >
              Global Change
            </Button>
            <Button
              startIcon={<MailOutline />}
              color={"secondary"}
              size={"small"}
              onClick={() => setOpenNL(true)}
            >
              Newsletter{" "}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Divider flexItem />
      <PdfUploadBox open={open} setOpen={setOpen} />
      <QualityControlBox open={openQC} setOpen={setOpenQC} />
      <GlobalChangeBox open={openGC} setOpen={setOpenGC} />
      <NewsletterBox open={openNL} setOpen={setOpenNL} />
    </Box>
  );
};
