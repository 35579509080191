import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { EltrpModal } from "../../../components/ui/modal/eltrp-modal";
import { deleteAccount } from "../user.service";
import { useEnv } from "../../../utils/context/env-context";
import { useAlert } from "../../../utils/context/alert-context";
import { LoadingButton } from "../../../components/ui/buttons/loading-button";
import { useAuth } from "../../../utils/context/auth-context";

export const DeleteAccount = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const env = useEnv();
  const alert = useAlert();
  const auth = useAuth();

  const deleteUser = () => {
    setLoading(true);
    deleteAccount(setLoading, alert.handleAlert).subscribe((res) => {
      setLoading(false);
      setOpen(false);
      auth.checkAuthenticated();
    });
  };
  return (
    <Box>
      <Button
        variant={"contained"}
        color={"warning"}
        onClick={() => setOpen(true)}
        disabled={env.env === "ADMIN"}
      >
        Delete Account
      </Button>
      <EltrpModal
        title={"Delete Account"}
        open={open}
        handleClose={() => setOpen(false)}
      >
        <Box textAlign={"center"}>
          Are you sure you want to delete your account?
          <br />
          This action is irreversible and all data will be lost.
        </Box>
        <Box display={"flex"} gap={1} mt={2} justifyContent={"center"}>
          <Button
            variant={"contained"}
            color={"secondary"}
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            color={"warning"}
            onClick={deleteUser}
            title={"Delete Account"}
            loading={loading}
          />
        </Box>
      </EltrpModal>
    </Box>
  );
};
