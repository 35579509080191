import { EltrpPage } from "../../../layout/wrappers/eltrp-page";
import { EltrpHeader } from "../../../layout/wrappers/eltrp-header";
import { EltrpBody } from "../../../layout/wrappers/eltrp-body";
import { ChangePassword } from "./change-password";
import { EltrpText } from "../../../layout/wrappers/eltrp-text";
import { DeleteAccount } from "./delete-account";
import { UserAttributes } from "./user-attributes";

export const UserSettingsPage = () => {
  return (
    <EltrpPage>
      <EltrpHeader title={"User Settings"} />
      <EltrpBody>
        <EltrpText>
          <h2> User Attributes</h2>
          <UserAttributes />
        </EltrpText>
        <EltrpText>
          <h2> Change Password </h2>
          <ChangePassword />
        </EltrpText>
        <EltrpText>
          <h2> Delete Account</h2>
          <DeleteAccount />
        </EltrpText>
      </EltrpBody>
    </EltrpPage>
  );
};
