import Grid2 from "@mui/material/Unstable_Grid2";
import { SearchBox } from "../features/search/components/search-box";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { search } from "../features/search/search.service";
import { SearchResponse } from "../models/SearchResult";
import { SearchResults } from "../features/search/components/results";
import { EltrpPage } from "../layout/wrappers/eltrp-page";
import { EltrpBody } from "../layout/wrappers/eltrp-body";
import { EltrpLoading } from "../layout/wrappers/eltrp-loading";
import { DesktopAggregations } from "../components/aggregations/devices/desktop-aggregations";
import { EltrpSearchResults } from "../layout/wrappers/eltrp-search-results";
import { useAlert } from "../utils/context/alert-context";

export const SearchPage = () => {
  const [searchParams] = useSearchParams();
  const [results, setResults] = useState<SearchResponse>();
  const [loading, setLoading] = useState<boolean>(false);

  const alert = useAlert();

  useEffect(() => {
    const query = searchParams.get("query") || "";
    const from = searchParams.get("from") || "0";
    const size = searchParams.get("size") || "10";
    const sortOrder = searchParams.get("sortOrder") || "SCORE";

    const filters = searchParams;
    const filtersAsArray: string[] = [];

    filters.forEach((value, key) =>
      key !== "from" && key !== "query" && key !== "size" && key !== "sortOrder"
        ? filtersAsArray.push([key, value].join(":"))
        : undefined,
    );
    setLoading(true);
    search(
      query.trim(),
      filtersAsArray,
      alert.handleAlert,
      setLoading,
      from,
      size,
      sortOrder,
    ).subscribe((res) => {
      setLoading(false);
      setResults(res);
    });
  }, [searchParams, alert.handleAlert]);

  return (
    <EltrpPage>
      <EltrpLoading loading={loading} />
      <section className="searchBar">
        <Grid2 xs={12} display="flex" justifyContent={"center"}>
          {" "}
          <SearchBox />{" "}
        </Grid2>
      </section>
      <EltrpBody>
        <EltrpSearchResults
          hits={results?.hits?.total?.value || 0}
          aggregations={
            <DesktopAggregations aggregations={results?.aggs || []} />
          }
          results={
            <SearchResults
              results={results?.hits}
              aggregations={results?.aggs || []}
            />
          }
        />
      </EltrpBody>
    </EltrpPage>
  );
};
