import { PropsWithChildren } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Box } from "@mui/material";

export const EltrpText = (props: PropsWithChildren) => {
  const { children } = props;
  return (
    <Grid2 sm={11} smOffset={0.5} xl={10} xlOffset={1} pb={2}>
      <Box>{children}</Box>
    </Grid2>
  );
};
