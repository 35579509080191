import { PropsWithChildren } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";

export const EltrpBody = (props: PropsWithChildren) => {
  const { children } = props;
  return (
    <Grid2
      container
      xs={11}
      md={9}
      xl={8}
      mdOffset={1.5}
      xlOffset={2}
      xsOffset={0.5}
      flexDirection={"column"}
    >
      {children}
    </Grid2>
  );
};
