import { httpClient } from "../../utils/http/httpClient";
import { API } from "../../environment/api";
import { catchError, throwError } from "rxjs";
import { ISignInRequest } from "../../models/SignInRequest";

export function signIn(
  data: ISignInRequest,
  setError: (err: boolean) => void,
  setLoading: (val: boolean) => void,
) {
  return httpClient.post(API.auth.login, data).pipe(
    catchError((err) => {
      console.log(err);
      setError(true);
      setLoading(false);
      return throwError(err);
    }),
  );
}

export function signOut() {
  return httpClient.post(API.auth.logout);
}

export function revoke(refreshToken: string, setError: (err: string) => void) {
  return httpClient.post(API.auth.revoke, { refreshToken }).pipe(
    catchError((err) => {
      setError(err.message);
      return throwError(err);
    }),
  );
}

export function getAuth(setIsAuth: (val: boolean) => void) {
  return httpClient.get(API.auth.auth, { authenticated: true }).pipe(
    catchError((err) => {
      err.response.status === 401 && setIsAuth(false);
      return throwError(err);
    }),
  );
}

export function getAuth2() {
  return httpClient.get(API.auth.auth, { authenticated: true }).pipe(
    catchError((err) => {
      return throwError(err);
    }),
  );
}

export function getEnv() {
  return httpClient.get(API.env);
}
