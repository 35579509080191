export const LinkElement = (props: { url: string[] }) => {
  const { url } = props;
  return (
    <span>
      {url[0] + " "}
      <a href={url[1]} target="_blank" rel="noreferrer">
        {url[1]}
      </a>
      {url[2] ? " (" + url[2] + ")" : ""}
    </span>
  );
};
